import React from 'react';
import { useTranslation } from 'react-i18next';
import {
    WelcomeScreenContainer,
    WelcomeTitle,
    WelcomeSubtitle,
} from './VendorBasicDetailsForm.styled';
import { ReactComponent as Checkmark } from '../../../assets/logos/checkmark-circle.svg';

const WelcomeBanner = () => {
    const { t } = useTranslation();

    return (
        <WelcomeScreenContainer className="welcomeBannerContainer" data-testid="welcome-container">
            <Checkmark />
            <div className="messageBox">
                <WelcomeTitle>{t('Forms.BasicDetails.WelcomeBan.Title')}</WelcomeTitle>
                <WelcomeSubtitle>{t('Forms.BasicDetails.WelcomeBan.Subtitle')}</WelcomeSubtitle>
            </div>
        </WelcomeScreenContainer>
    );
};

export default WelcomeBanner;
