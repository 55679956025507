import React, { useEffect, useState } from 'react';
import { vendorBasicDetailsFormSchemaWrapper } from 'vendor/constants/schema/VendorBasicDetailsFormSchema';
import { VendorBasicDetailsFormStyled } from './VendorBasicDetailsForm.styled';
import { VendorAccountSetupStepFooter, PearlApps } from 'framework';
import PearlForm from 'vendor/components/forms/PearlForm';
import { usePearlModal, VendorAccountSetupStepLayout } from 'framework';
import { NewVendorWebstoreSetupHeader } from '../WebstoreSetup/NewVendorWebstoreSetupHeader';
import VerificationModalContent from './VerificationModalContent';
import WelcomeBanner from './WelcomeBanner';
import VendorDetailsForm from './VendorDetailsForm';
import MobileVerificationPage from './MobileVerificationPage';
import { useStore } from 'stores/Store';
import { VendorAuthService } from 'lib/apis/VendorAuthService';
import { TokenService } from 'lib/apis/TokenService';
import { useNavigate } from 'react-router-dom';
import { setNewVendor, useAppName } from 'shared/hooks/AppName';
import { APP_ROUTES } from 'shared/constants/navigation';
import { WebstoreService } from 'shared/lib/apis/WebstoreService';
import { IconSizes, PearlIcon } from 'dbi-pearl-ui-kit';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

const VerifiedEmailStyled = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    background: #00a02d;
    height: 52px;
    gap: 16px;
    margin-bottom: 48px;
    position: sticky;
    top: 0;
    z-index: 1000;
    @media (max-width: 550px) {
        margin-bottom: 0px;
    }
    .checkmarkStyle {
        padding-bottom: 4px;
    }
    .emailVerifiedStyle {
        color: #fff;
        font-family: 'Objektiv Mk2';
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 24px;
    }
`;

export const Footer = ({ handleSubmit, isLetsGo, isSubmit, handleContinue }) => {
    return (
        <VendorAccountSetupStepFooter
            progress={''}
            isSubmit={isSubmit}
            isLetsGo={isLetsGo}
            handleSubmit={handleSubmit}
            hideStepCount={false}
            showBack={false}
            handleContinue={handleContinue}
        />
    );
};

const VendorBasicDetailsForm = () => {
    const { appName } = useAppName();
    const { openModal, closeModal } = usePearlModal();
    const [welcomeBanner, setWelcomeBanner] = useState(false);
    const [showMobileVerification, setShowMobileVerification] = useState(false);
    const [signUpError, setSignUpError] = useState('');
    const { t } = useTranslation();

    const [isPasswordStrong, setIsPasswordStrong] = useState(false);
    const webstoreApi = WebstoreService();
    const { eventUser, event, authUser, vendor, webstore } = useStore();
    const formName = welcomeBanner ? 'WelcomeBanner' : 'BasicDetails';
    const navigate = useNavigate();
    const formOptions = {
        mode: 'onChange',
        defaultValues: {},
    };

    useEffect(() => {
        setNewVendor('newVendor');
        return () => {
            closeModal();
        };
    }, []);
    const onVerifyHandler = () => {
        setWelcomeBanner(true);
    };

    const openVerificationModal = () => {
        if (true) {
            openModal(
                <VerificationModalContent onVerify={onVerifyHandler} email={authUser.Email} />,
                '',
                '',
                null,
                false,
                '',
                0,
                true
            );
        }
    };

    const handleContinue = async () => {
        await eventUser.Reaction;
        await event.Reaction;
        await vendor.Reaction;
        if (appName === PearlApps.VENDOR) {
            if (vendor && vendor.id) {
                navigate(APP_ROUTES.vendor.dashboard);
            } else {
                navigate(APP_ROUTES.vendor.auth.welcome);
            }
        } else {
            navigate(APP_ROUTES.landing);
        }
    };

    const handlePasswordStrengthChange = (isStrong) => {
        setIsPasswordStrong(isStrong);
    };

    const handleRegistration = async (data) => {
        const {
            Emailaddress,
            Enterpassword,
            Firstname,
            Lastname,
            Phonenumber,
            Confirmpassword,
            Agree,
        } = data;
        if (
            !Agree ||
            !Emailaddress ||
            !Enterpassword ||
            !Firstname ||
            !Lastname ||
            !Phonenumber ||
            !Confirmpassword
        ) {
            return;
        }
        if (Enterpassword !== Confirmpassword) {
            return;
        }
        if (!isPasswordStrong) {
            return;
        }
        authUser.updateAuthUserFromNewSignup(data);
        authUser.email = Emailaddress;

        const vendorAuthService = VendorAuthService();
        try {
            const response = await vendorAuthService.signUpVendor(
                Emailaddress,
                Enterpassword,
                '+' + Phonenumber,
                Firstname,
                Lastname
            );
            if (response?.data) {
                const tokenService = TokenService();
                const {
                    data: { authResult: tokens, user: currentUser },
                } = response;
                tokenService.setAccessToken(tokens.AccessToken);
                tokenService.setIdToken(tokens.IdToken);
                tokenService.setRefreshToken(tokens.RefreshToken);
                tokenService.setExpiry(tokens.ExpiresIn);
                try {
                    if (currentUser?.id) {
                        authUser.setAuthUser(currentUser);
                        const userWebstore = await webstoreApi.getWebstoreByUserId(authUser.Id);

                        if (!!userWebstore?.id) {
                            authUser.Roles.Vendor = true;
                            webstore.setFromApi(userWebstore);
                        }
                    }
                } catch (e) {
                    console.error('error', e);
                }

                const isMobileView = window.innerWidth <= 650;
                if (isMobileView) {
                    setShowMobileVerification(true);
                } else {
                    openVerificationModal();
                }
            }
        } catch (error) {
            setSignUpError(error);
        }
    };

    const vendorBusinessDetailsFormSchema = vendorBasicDetailsFormSchemaWrapper();

    return (
        <>
            {welcomeBanner && (
                <VerifiedEmailStyled>
                    <PearlIcon
                        className="checkmarkStyle"
                        iconName="checkmark"
                        size={IconSizes.LG}
                    />
                    <p className="emailVerifiedStyle">{t('Forms.WelcomeBanner.Message')}</p>
                </VerifiedEmailStyled>
            )}
            <PearlForm
                StyledForm={VendorBasicDetailsFormStyled}
                formName={formName}
                formOptions={formOptions}
                validationSchema={vendorBusinessDetailsFormSchema}
            >
                {!welcomeBanner && !showMobileVerification ? (
                    <VendorAccountSetupStepLayout
                        header={
                            <NewVendorWebstoreSetupHeader
                                stepName={formName}
                                hideStepCount={true}
                            />
                        }
                        formComponent={
                            <VendorDetailsForm
                                signUpError={signUpError}
                                onPasswordStrengthChange={handlePasswordStrengthChange}
                            />
                        }
                        footer={<Footer isSubmit={true} handleSubmit={handleRegistration} />}
                    />
                ) : welcomeBanner ? (
                    <VendorAccountSetupStepLayout
                        header={
                            <NewVendorWebstoreSetupHeader
                                stepName={formName}
                                hideStepCount={true}
                            />
                        }
                        formComponent={<WelcomeBanner />}
                        footer={<Footer isLetsGo={true} handleContinue={handleContinue} />}
                    />
                ) : (
                    showMobileVerification && (
                        <MobileVerificationPage
                            onVerify={onVerifyHandler}
                            email={authUser.Email}
                            phoneNumber={authUser.PhoneNumber}
                        />
                    )
                )}
            </PearlForm>
        </>
    );
};

export { VendorBasicDetailsForm };
