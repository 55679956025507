import React from 'react';
import { ReactComponent as RedIndicator } from '../../../assets/logos/redIndicator.svg';
import { ReactComponent as GreenIndicator } from '../../../assets/logos/greenIndicator.svg';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const PasswordValidations = styled.div`
    margin-top: 8px;
    .passwordInstructionStyle {
        display: flex;
        gap: 8px;
        margin-top: 8px;
    }
    .passwordTextStyle {
        color: #0a5151;
        font-family: 'Objektiv Mk2';
        font-size: 14px;
        font-style: normal;
        font-weight: 300;
        line-height: 18px;
        margin: 0px;
        padding-top: 2px;
    }
    .passwordStrength-weak {
        position: relative;
        top: 1px;
        left: -4px;
        color: #920505;
        font-family: 'Objektiv Mk2';
        font-size: 12px;
        font-style: normal;
        font-weight: 700;
        line-height: 18px;
    }

    .passwordStrength-medium {
        position: relative;
        top: 1px;
        left: -4px;
        color: #920505;
        font-family: 'Objektiv Mk2';
        font-size: 12px;
        font-style: normal;
        font-weight: 700;
        line-height: 18px;
    }
    .passwordStrength-strong {
        position: relative;
        top: 1px;
        left: -4px;
        color: #0a5151;
        font-family: 'Objektiv Mk2';
        font-size: 12px;
        font-style: normal;
        font-weight: 700;
        line-height: 18px;
    }
    .indicatorStyle {
        margin: 2px;
        height: 16px;
    }
`;

const PasswordValidation = ({ passwordStrength = 'weak', validationStatus = {} }) => {
    const { t } = useTranslation();
    const renderIcon = (isValid) =>
        isValid ? (
            <GreenIndicator data-testid="green-indicator" className="indicatorStyle" />
        ) : (
            <RedIndicator data-testid="red-indicator" className="indicatorStyle" />
        );

    return (
        <PasswordValidations>
            <div className="passwordInstructionStyle">
                {renderIcon(passwordStrength !== 'weak')}
                <p className="passwordTextStyle">
                    {t('Forms.Validation.PasswordInstructions.Subtext1')}
                </p>
                <span className={`passwordStrength-${passwordStrength}`}>{passwordStrength}</span>
            </div>
            <div className="passwordInstructionStyle">
                {renderIcon(validationStatus.noEmailOrName)}
                <p className="passwordTextStyle">
                    {t('Forms.Validation.PasswordInstructions.Subtext2')}
                </p>
            </div>
            <div className="passwordInstructionStyle">
                {renderIcon(validationStatus.length)}
                <p className="passwordTextStyle">
                    {t('Forms.Validation.PasswordInstructions.Subtext3')}
                </p>
            </div>
            <div className="passwordInstructionStyle">
                {renderIcon(validationStatus.numberOrSymbol)}
                <p className="passwordTextStyle">
                    {t('Forms.Validation.PasswordInstructions.Subtext4')}
                </p>
            </div>
            <div className="passwordInstructionStyle">
                {renderIcon(validationStatus.uppercaseLowercase)}
                <p className="passwordTextStyle">
                    {t('Forms.Validation.PasswordInstructions.Subtext5')}
                </p>
            </div>
        </PasswordValidations>
    );
};

PasswordValidation.propTypes = {
    passwordStrength: PropTypes.string,
    validationStatus: PropTypes.shape({
        noEmailOrName: PropTypes.bool,
        length: PropTypes.bool,
        numberOrSymbol: PropTypes.bool,
        uppercaseLowercase: PropTypes.bool,
    }),
};

export default PasswordValidation;
