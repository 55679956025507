import React from 'react';
import PropTypes from 'prop-types';
import { SolidButton, IconName, PearlIcon, IconSizes } from 'dbi-pearl-ui-kit';
import useWindowSize from 'vendor/hooks/useWindowSize';
import { useFormContext } from 'react-hook-form';
import {
    Tile,
    Title,
    Price,
    Period,
    Description,
    Benefits,
    Benefit,
    TrialLink,
    Highlight,
    PopularBadge,
    ToggleIcon,
    HiddenRadio,
    TileBody,
    Duration,
    PriceContainer,
} from './PickYourPlanTile.styled';

const PickYourPlanTile = ({
    title,
    price,
    value,
    altPrice,
    altPriceLink,
    description,
    benefits,
    isPopular,
    planId,
    formFieldName,
    onClick,
    isSelected,
    isOpen,
    onToggleClick,
    planInfoBtn,
    period,
}) => {
    const { register, setValue } = useFormContext();
    const handleTileClick = () => {
        setValue(formFieldName, value);
        onClick();
    };
    const { width } = useWindowSize();
    const getDescription = (description) => {
        const upperCaseString = description.toUpperCase();
        if (upperCaseString.includes('STARTER')) {
            return (
                <>
                    Everything in <Highlight>STARTER</Highlight>, plus:
                </>
            );
        } else if (upperCaseString.includes('ESSENTIAL')) {
            return (
                <>
                    Everything in <Highlight>ESSENTIAL</Highlight>, plus:
                </>
            );
        }
        return description;
    };

    return (
        <Tile
            onClick={handleTileClick}
            isPopular={isPopular}
            isSelected={isSelected}
            isOpen={isOpen}
        >
            <HiddenRadio
                type="radio"
                id={planId}
                data-testid={planId}
                name="plan-tier"
                value={value}
                defaultChecked={isSelected}
                readOnly
                {...register(formFieldName)}
            />
            {isPopular && <PopularBadge>{`Most Popular! $${value} ${period}*`}</PopularBadge>}
            <TileBody>
                <Title isPopular={isPopular}>
                    {title}
                    <ToggleIcon onClick={onToggleClick}>
                        {isOpen ? (
                            <PearlIcon
                                className="minus"
                                iconName={IconName.MINUS}
                                size={IconSizes.MD}
                            />
                        ) : (
                            <PearlIcon
                                className="plus"
                                iconName={IconName.PLUS}
                                size={IconSizes.MD}
                            />
                        )}
                    </ToggleIcon>
                </Title>
                {(isOpen || width > 768) && (
                    <>
                        <PriceContainer>
                            <Price>{`$${value}`}</Price>
                            <Duration>{`${period}*`}</Duration>
                        </PriceContainer>
                        <SolidButton
                            data-testid="trial-button"
                            onClick={onClick}
                            variant="primary"
                            className="saveButton"
                        >
                            {planInfoBtn}
                        </SolidButton>
                        <Period>
                            <span>{altPrice}</span>
                            {altPriceLink && <TrialLink href="#">{altPriceLink}</TrialLink>}
                        </Period>
                        <Description> {getDescription(description)}</Description>
                        <Benefits>
                            {benefits?.map((benefit, index) => (
                                <Benefit key={index}>{benefit}</Benefit>
                            ))}
                        </Benefits>
                    </>
                )}
            </TileBody>
        </Tile>
    );
};

PickYourPlanTile.propTypes = {
    title: PropTypes.string,
    price: PropTypes.string,
    period: PropTypes.string,
    periodLink: PropTypes.string,
    description: PropTypes.string,
    benefits: PropTypes.arrayOf(PropTypes.string),
    isPopular: PropTypes.bool,
    planId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    formFieldName: PropTypes.string,
    onClick: PropTypes.func,
    isSelected: PropTypes.bool,
    isOpen: PropTypes.bool,
    onToggleClick: PropTypes.func,
    planInfoBtn: PropTypes.string,
};

PickYourPlanTile.defaultProps = {
    isPopular: false,
};

export default PickYourPlanTile;
