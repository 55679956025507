import React, { useEffect, useState } from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import { useTranslation, Trans } from 'react-i18next';
import { TextInput, Checkbox, PearlIcon, IconSizes } from 'dbi-pearl-ui-kit';
import { FormRow, PearlError, PhoneNumberInput, NewTabLink } from 'framework';
import { FormComponent } from './VendorBasicDetailsForm.styled';
import { APP_ROUTES } from '../../../shared/constants/navigation';
import { ReactComponent as HideView } from '../../../assets/logos/hideView.svg';
import PasswordValidation from './PasswordValidation';

const AGREE_FIELD = 'Agree';

const VendorDetailsForm = ({ signUpError, onPasswordStrengthChange }) => {
    const { t } = useTranslation();
    const { register, setValue, control, watch, trigger } = useFormContext();
    const [isChecked, setIsChecked] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [passwordStrength, setPasswordStrength] = useState('weak');
    const [validationStatus, setValidationStatus] = useState({
        length: false,
        uppercaseLowercase: false,
        numberOrSymbol: false,
        noEmailOrName: false,
    });

    const togglePasswordVisibility = () => {
        setShowPassword((prevState) => !prevState);
    };
    const toggleConfirmPasswordVisibility = () => {
        setShowConfirmPassword((prevState) => !prevState);
    };
    const passwordValue = watch('Enterpassword');
    const confirmPasswordValue = watch('Confirmpassword');
    const emailValue = watch('Emailaddress');
    useEffect(() => {
        if (emailValue) {
            const lowercaseEmail = emailValue.toLowerCase();
            if (emailValue !== lowercaseEmail) {
                setValue('Emailaddress', lowercaseEmail);
            }
        }
    }, [emailValue, setValue]);
    useEffect(() => {
        // eslint-disable-next-line complexity
        const validatePassword = () => {
            const email = watch('Emailaddress') || '';
            const firstName = watch('Firstname') || '';
            const lastName = watch('Lastname') || '';
            const length = passwordValue?.length >= 8;
            const uppercaseLowercase = /[A-Z]/.test(passwordValue) && /[a-z]/.test(passwordValue);
            const numberOrSymbol =
                /\d/.test(passwordValue) && /[!@#$%^&*(),.?":{}|<>]/.test(passwordValue);
            const noEmailOrName =
                !passwordValue ||
                (!email && !firstName && !lastName) ||
                (passwordValue &&
                    (!email ||
                        !passwordValue
                            .toLowerCase()
                            .includes(email.split('@')[0]?.toLowerCase())) &&
                    (!firstName ||
                        !passwordValue.toLowerCase().includes(firstName.toLowerCase())) &&
                    (!lastName || !passwordValue.toLowerCase().includes(lastName.toLowerCase())));

            setValidationStatus({
                length,
                uppercaseLowercase,
                numberOrSymbol,
                noEmailOrName,
            });

            if (length && uppercaseLowercase && numberOrSymbol && noEmailOrName) {
                setPasswordStrength('strong');
            } else if (length && (uppercaseLowercase || numberOrSymbol)) {
                setPasswordStrength('medium');
            } else {
                setPasswordStrength('weak');
            }
        };

        validatePassword();
    }, [passwordValue, watch('Firstname'), watch('Lastname'), watch('Emailaddress')]);

    register(AGREE_FIELD, { value: false });

    const handleAgreeChange = (val) => {
        setIsChecked(val);
        setValue(AGREE_FIELD, val);
    };

    useEffect(() => {
        if (confirmPasswordValue) {
            trigger('Confirmpassword');
        }
    }, [passwordValue, confirmPasswordValue, trigger]);

    useEffect(() => {
        if (onPasswordStrengthChange) {
            onPasswordStrengthChange(passwordStrength === 'strong');
        }
    }, [passwordStrength, onPasswordStrengthChange]);

    return (
        <FormComponent>
            <h1 className="sectionTitle">{t('Forms.BasicDetails.YourInfo')}</h1>
            <FormRow
                className="section-2"
                columns={[
                    <div key={0}>
                        <TextInput formFieldName="Firstname" />
                        <PearlError name="Firstname" />
                    </div>,
                    <div key={1}>
                        <TextInput formFieldName="Lastname" />
                        <PearlError name="Lastname" />
                    </div>,
                ]}
            />
            <FormRow
                className="section-3"
                columns={[
                    <div key={1} className="phoneNumberStyle">
                        <Controller
                            name={'Phonenumber'}
                            control={control}
                            render={({ field }) => (
                                <PhoneNumberInput field={field} className="custom-phone-input" />
                            )}
                        />
                        <PearlError name="Phonenumber" />
                    </div>,
                    <div key={0}>
                        <TextInput formFieldName="Emailaddress" type="email" />
                        <PearlError name="Emailaddress" />
                    </div>,
                ]}
            />
            <div className="passwordSection">
                <FormRow
                    className={'section-3'}
                    columns={[
                        <div key={0} className="passwordInstructionPosition">
                            <TextInput
                                formFieldName="Enterpassword"
                                type={showPassword ? 'text' : 'password'}
                            />
                            <PearlError name="Enterpassword" />
                            {showPassword && (
                                <PearlIcon
                                    iconName="view"
                                    size={IconSizes.MD}
                                    onClick={togglePasswordVisibility}
                                    className="eyeIconPosition"
                                />
                            )}
                            {!showPassword && (
                                <HideView
                                    onClick={togglePasswordVisibility}
                                    className="eyeIconPosition"
                                />
                            )}
                            {passwordValue && (
                                <PasswordValidation
                                    passwordStrength={passwordStrength}
                                    validationStatus={validationStatus}
                                />
                            )}
                        </div>,
                        <div key={1} className="passwordInstructionPosition">
                            <TextInput
                                formFieldName="Confirmpassword"
                                type={showConfirmPassword ? 'text' : 'password'}
                            />
                            <PearlError name="Confirmpassword" />
                            {showConfirmPassword && (
                                <PearlIcon
                                    iconName="view"
                                    size={IconSizes.MD}
                                    onClick={toggleConfirmPasswordVisibility}
                                    className="eyeIconPosition"
                                />
                            )}
                            {!showConfirmPassword && (
                                <HideView
                                    onClick={toggleConfirmPasswordVisibility}
                                    className="eyeIconPosition"
                                />
                            )}
                        </div>,
                    ]}
                />
            </div>
            <div className="termsTitle">{t('Forms.BasicDetails.TermsAndConditions')}</div>
            <p className="acceptTerms">{t('Forms.BasicDetails.AcceptTerms')}</p>
            <div className="agreement">
                <Checkbox name={AGREE_FIELD} onChange={handleAgreeChange} />
                <p className="agreeTerms">
                    <Trans
                        i18nKey="Forms.BasicDetails.AgreeTerms"
                        components={{
                            vendorAgreement: (
                                <NewTabLink
                                    link={
                                        process.env.REACT_APP_BASE_URL +
                                        APP_ROUTES.vendor.vendorAgreement
                                    }
                                />
                            ),
                        }}
                    />
                    {!isChecked && <PearlError name={AGREE_FIELD} />}
                </p>
            </div>
            <p className="alreadyHaveAccount">
                {t('Forms.BasicDetails.AlreadyHaveAccount')}
                <a href={APP_ROUTES.vendorLogin}>{t('Forms.BasicDetails.Login')}</a>
            </p>
            {signUpError && <div className="signUpError">{signUpError}</div>}
        </FormComponent>
    );
};

export default VendorDetailsForm;
