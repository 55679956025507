/* eslint-disable complexity */
import { PearlApps, PearlForm, usePearlToast } from 'framework';
import React, { useEffect, useState } from 'react';
import { useStore } from 'stores/Store';
import { vendorLoginFormSchemaWrapper } from './VendorLoginPageSchema';
import VendorLoginForm from './VendorLoginForm';
import { VendorAuthService } from 'lib/apis/VendorAuthService';
import { TokenService } from 'lib/apis/TokenService';
import { WebstoreService } from 'shared/lib/apis/WebstoreService';
import { useNavigate } from 'react-router-dom';
import { APP_ROUTES } from 'shared/constants/navigation';
import { setAppName, setAuthType, setNewVendor, useAppName } from 'shared/hooks/AppName';

const VendorLoginPage = () => {
    const { appName } = useAppName();
    const navigate = useNavigate();
    const tokenService = TokenService();
    const { eventUser, event, authUser, vendor, webstore } = useStore();
    const { toastSuccess, toastError } = usePearlToast();
    const [showPasswordInput, setShowPasswordInput] = useState(false);
    const [showOTPInput, setShowOTPInput] = useState(false);
    const [showResetPasswordInputs, setShowResetPasswordInputs] = useState(false);
    const [showSignUpButton, setShowSignUpButton] = useState(false);
    const [showPhoneNumber, setShowPhoneNumber] = useState(false);
    const [showFirstandLastNames, setShowFirstandLastNames] = useState(false);
    const [userFieldsReq, setUserFieldsReq] = useState([]);
    const formOptions = {
        mode: 'onChange',
        defaultValues: {
            Emailaddress: authUser.Email,
        },
    };
    const vendorAuthService = VendorAuthService();
    const webstoreApi = WebstoreService();

    useEffect(() => {
        setNewVendor('newVendor');
    }, []);

    const LoginClickHandler = async (data, setError) => {
        if (!data?.Emailaddress) {
            return;
        }
        try {
            const response = await vendorAuthService.checkEmailAvailability(data.Emailaddress);
            if (response?.data?.newPoolOrOldPool === 'OLD') {
                setShowOTPInput(true);
                setShowPasswordInput(false);
                setShowSignUpButton(false);
                if (response?.data?.userFieldsRequired.length > 0) {
                    const userFieldsRequired = response?.data?.userFieldsRequired;
                    setUserFieldsReq(userFieldsRequired);
                    const hasPhoneNumber = userFieldsRequired.includes('phoneNumber');
                    const hasFirstNameOrLastName =
                        userFieldsRequired.includes('firstName') ||
                        userFieldsRequired.includes('lastName');

                    setShowPhoneNumber(hasPhoneNumber);
                    setShowFirstandLastNames(hasFirstNameOrLastName);
                }
            } else if (response?.data?.newPoolOrOldPool === 'NEW') {
                setShowPasswordInput(true);
                setShowOTPInput(false);
                setShowSignUpButton(false);
            }
        } catch (e) {
            setError('Emailaddress', {
                type: 'manual',
                message: 'Entered email address not found. Please sign up.',
            });
            setShowOTPInput(false);
            setShowPasswordInput(false);
            setShowSignUpButton(true);
        }
    };

    const handleOTPSubmit = async (data, setError) => {
        const { Emailaddress, EnterOTP } = data;
        if (!Emailaddress || !EnterOTP) {
            return;
        }
        try {
            await vendorAuthService.verifyEmailOTP({
                email: Emailaddress,
                otp: EnterOTP,
                newOrOld: 'OLD',
            });
            toastSuccess(
                "Welcome back! We've had a major upgrade. Reset your password to log in to your account."
            );
            setShowOTPInput(false);
            setShowResetPasswordInputs(true);
        } catch (e) {
            setError('EnterOTP', {
                type: 'manual',
                message: 'OTP is incorrect or expired',
            });
        }
    };

    const handleSignUp = () => {
        setAppName(appName);
        setAuthType('signup');

        navigate(APP_ROUTES.vendorNew);
    };

    const handlePasswordResetSubmit = async (data, setError) => {
        const {
            Emailaddress,
            Enterpassword,
            Confirmpassword,
            Firstname = '',
            Lastname = '',
            Phonenumber = '',
        } = data;
        const formattedPhoneNumber = Phonenumber ? '+' + Phonenumber : '';
        if (
            !Emailaddress ||
            !Enterpassword ||
            !Confirmpassword ||
            (userFieldsReq.includes('firstName') && !Firstname) ||
            (userFieldsReq.includes('lastName') && !Lastname) ||
            (userFieldsReq.includes('phoneNumber') && !formattedPhoneNumber)
        ) {
            return;
        }
        try {
            // creating user in cognito user pool
            const response = await vendorAuthService.createUser(
                Emailaddress,
                Enterpassword,
                Firstname,
                Lastname,
                formattedPhoneNumber
            );
            if (response?.data) {
                toastSuccess('Password successfully set');
                const {
                    data: { authResult: tokens, user: currentUser },
                } = response;
                tokenService.setAccessToken(tokens.AccessToken);
                tokenService.setIdToken(tokens.IdToken);
                tokenService.setRefreshToken(tokens.RefreshToken);
                tokenService.setExpiry(tokens.ExpiresIn);
                try {
                    if (currentUser?.id) {
                        authUser.setAuthUser(currentUser);
                        const userWebstore = await webstoreApi.getWebstoreByUserId(authUser.Id);

                        if (!!userWebstore?.id) {
                            authUser.Roles.Vendor = true;
                            webstore.setFromApi(userWebstore);
                        }
                    }
                } catch (e) {
                    console.error('error', e);
                }

                await eventUser.Reaction;
                await event.Reaction;
                await vendor.Reaction;
                if (appName === PearlApps.VENDOR) {
                    if (vendor && vendor.id) {
                        navigate(APP_ROUTES.vendor.dashboard);
                    } else {
                        navigate(APP_ROUTES.vendor.auth.welcome);
                    }
                } else {
                    navigate(APP_ROUTES.landing);
                }
            }
        } catch (e) {
            toastError(e);
        }
    };

    const handleLogin = async (data) => {
        const { Password, Emailaddress } = data;
        if (!Emailaddress || !Password) {
            return;
        }
        try {
            const loginResponse = await vendorAuthService.loginVendor({
                identifier: Emailaddress,
                password: Password,
            });
            if (loginResponse?.data?.authResult) {
                const {
                    data: { authResult: tokens, user: currentUser },
                } = loginResponse;
                tokenService.setAccessToken(tokens.AccessToken);
                tokenService.setIdToken(tokens.IdToken);
                tokenService.setRefreshToken(tokens.RefreshToken);
                tokenService.setExpiry(tokens.ExpiresIn);
                try {
                    if (currentUser?.id) {
                        authUser.setAuthUser(currentUser);
                        const userWebstore = await webstoreApi.getWebstoreByUserId(authUser.Id);

                        if (!!userWebstore?.id) {
                            authUser.Roles.Vendor = true;
                            webstore.setFromApi(userWebstore);
                        }
                    }
                } catch (e) {
                    console.error('error', e);
                }

                await eventUser.Reaction;
                await event.Reaction;
                await vendor.Reaction;
                if (appName === PearlApps.VENDOR) {
                    if (vendor && vendor.id) {
                        navigate(APP_ROUTES.vendor.dashboard);
                    } else {
                        navigate(APP_ROUTES.vendor.auth.welcome);
                    }
                } else {
                    navigate(APP_ROUTES.landing);
                }
            }
        } catch (e) {
            toastError(e);
        }
    };
    const VendorLoginPageSchema = vendorLoginFormSchemaWrapper();

    return (
        <PearlForm
            formName={'BasicDetails'}
            formOptions={formOptions}
            validationSchema={VendorLoginPageSchema}
        >
            <VendorLoginForm
                showPasswordInput={showPasswordInput}
                showOTPInput={showOTPInput}
                showResetPasswordInputs={showResetPasswordInputs}
                showSignUpButton={showSignUpButton}
                LoginClickHandler={LoginClickHandler}
                handleOTPSubmit={handleOTPSubmit}
                handleSignUp={handleSignUp}
                handlePasswordResetSubmit={handlePasswordResetSubmit}
                handleLogin={handleLogin}
                showFirstandLastNames={showFirstandLastNames}
                showPhoneNumber={showPhoneNumber}
                userFieldsReq={userFieldsReq}
            />
        </PearlForm>
    );
};

export { VendorLoginPage };
