import { makeAutoObservable } from 'mobx';
import { initAuthUserStore } from './Initializers';
import jwtDecode from 'jwt-decode';
import { StorageService } from 'lib/StorageService';

const externalIdKeys = {
    customerHub: 'customerHub',
};

const initialValues = {};

export default class AuthUserStore {
    Id = null;
    Username = null;
    Email = null;
    FirstName = null;
    LastName = null;
    Birthdate = null;
    PhoneNumber = null;
    ExternalIds = {};
    Props = {
        favorites: [],
        completedWebstoreTutorial: false,
    };
    Settings = null;

    Roles = {
        Admin: null,
        Vendor: null,
    };

    constructor(options) {
        for (const [k, v] of Object.entries(this)) {
            initialValues[k] = v;
        }

        makeAutoObservable(this);

        if (options?.useInitializers !== false) {
            this.Initialized = initAuthUserStore(this);
        }
    }

    get isAuthed() {
        return !!this.Username;
    }

    get isAuthedEventUser() {
        return !!this.Username;
    }

    get isAuthedVendor() {
        return !!this.Username && this.Roles.Vendor;
    }

    setAuthUser(authUser) {
        this.Id = parseInt(authUser.id, 10);
        this.Username = authUser.username;
        this.Email = authUser.email;
        this.IsAdmin = authUser.isAdmin;
        this.FirstName = authUser.firstName;
        this.LastName = authUser.lastName;
        this.Birthdate = authUser.birthdate;
        this.PhoneNumber = authUser.phoneNumber;
        this.ExternalIds = authUser.externalIds;
        this.Props = authUser.props || {};
        if (authUser.settings) {
            this.Settings = { ...this.Settings, ...authUser.settings };
        }
        const storageService = StorageService();
        storageService.set('username', authUser.username);
    }

    get forApi() {
        const data = {
            id: this.Id,
            settings: this.Settings,
            props: this.Props,
        };

        return data;
    }

    setLoading(value) {
        this.Loading = value;
    }

    setDefaults() {
        for (const [k, v] of Object.entries(initialValues)) {
            this[k] = v;
        }
    }

    updateAuthUserFromNewSignup(userData) {
        this.Email = userData.Emailaddress;
        this.FirstName = userData.Firstname;
        this.LastName = userData.Lastname;
        this.PhoneNumber = '+' + userData.Phonenumber;
    }

    updateAuthUser(update) {
        for (const [key, value] of Object.entries(update)) {
            if (this.hasOwnProperty(key)) {
                this[key] = value;
            }
        }
    }

    setCompletedWebstoreTutorial(value) {
        this.Props.completedWebstoreTutorial = value;
    }

    addFavorite(id) {
        if (this.Props.favorites) {
            this.Props.favorites.push(id);
        } else {
            this.Props.favorites = [id];
        }
    }

    removeFavorite(id) {
        if (this.Props.favorites) {
            this.Props.favorites.splice(this.Props.favorites.indexOf(id), 1);
        }
    }

    isFavorite(id) {
        return this.Props.favorites?.includes(id);
    }

    clear() {
        this.setDefaults();
    }

    updateSettings(settingCategory, setting) {
        let updatedCategory;
        if (this.Settings && this.Settings[settingCategory]) {
            updatedCategory = {
                [settingCategory]: {
                    ...this.Settings[settingCategory],
                    ...setting,
                },
            };
        } else {
            updatedCategory = {
                [settingCategory]: {
                    ...setting,
                },
            };
        }

        this.Settings = { ...this.Settings, ...updatedCategory };
    }

    equalsIdToken(idToken) {
        const decodedIdToken = jwtDecode(idToken);

        let birthdate = null;

        if (decodedIdToken?.birthdate) {
            // convert from sso 'yyyy-mm-dd' to 'mm/dd/yyyy'
            const birthdateParts = decodedIdToken?.birthdate?.split('-');
            birthdateParts.push(birthdateParts.shift());
            birthdate = birthdateParts.join('/');
        }

        const isEqual =
            this.ExternalIds?.[externalIdKeys.customerHub]?.value ===
                (decodedIdToken.ch_id || null) &&
            this.FirstName === (decodedIdToken.given_name || null) &&
            this.LastName === (decodedIdToken.family_name || null) &&
            this.Birthdate === (birthdate || null) &&
            this.PhoneNumber === (decodedIdToken.phone_number || null);

        return isEqual;
    }
}
