import styled from 'styled-components';

const PearlErrorStyled = styled.div`
    color: red;
    font-size: 12px;
    padding-top: 8px;
    padding-left: 16px;
`;

export { PearlErrorStyled };
