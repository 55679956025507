const StorageKeys = {
    appName: 'appName',
    internalCallbackUrl: 'internalCallbackUrl',
    webstorePublishSuccess: 'PEARL_WEBSTORE_PUBLISH_SUCCESS',
    cybInProgressWebstore: 'PEARL_CYB_IN_PROGRESS_WEBSTORE',
    authType: 'authType',
    newVendor: 'newVendor',
    vendorCategories: 'vendorCategories',
    VendorFaqOptions: 'VendorFaqOptions',
    currentLocation: 'whereAmI',
    featureToggles: 'featureToggles',
};

const StorageService = (storageLocation = window.localStorage) => {
    const get = (key) => storageLocation.getItem(key);

    const pop = (key) => {
        const value = storageLocation.getItem(key);
        storageLocation.removeItem(key);

        return value;
    };

    const set = (key, value) => storageLocation.setItem(key, value);

    const remove = (key) => {
        storageLocation.removeItem(key);
    };

    return {
        get,
        pop,
        set,
        remove,
    };
};

export { StorageService, StorageKeys };
