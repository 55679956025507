import apisauce from 'apisauce';
import { version } from '../../../../package.json';
import {
    displayPearlApiLoader,
    getRequestId,
    endLoader,
} from '../ApiLoaderUtility/ApiLoaderUtility';
import { TokenService } from '../../../lib/apis/TokenService';
import { StorageKeys, StorageService } from 'lib/StorageService';

const PearlApps = {
    VENDOR: 'vendor',
    EVENT_USER: 'event-user',
    ADMIN: 'admin',
    AUTH: 'auth',
};

const build = process.env.REACT_APP_BUILD_TAG;
const appVersion = `${version}` + (build ? `-${build}` : '');
const apiTimeout = 10000;

export function PearlApiClient(appName) {
    if (!Object.values(PearlApps).includes(appName)) {
        throw new Error('invalid appName');
    }
    const storage = StorageService();
    const newVendor = storage.get(StorageKeys.newVendor);
    const tokenService = TokenService();

    const client = apisauce.create({
        baseURL: process.env.REACT_APP_API_URL,
        timeout: apiTimeout,
        headers: {
            'pearl-app': appName,
            'pearl-app-version': appVersion,
            ...(newVendor ? { 'new-vendor-signup': true } : {}),
        },
    });

    let activeRequests = {};

    client.axiosInstance.interceptors.request.use(
        async (config) => {
            config.requestInitiationTime = Date.now();

            activeRequests[`url-${config.url}_id-${config.requestInitiationTime}`] = {
                LoaderDisplayed: false,
                Timeout: null,
            };

            try {
                displayPearlApiLoader(config, activeRequests);
            } catch (err) {
                console.log(err);
            }

            const accessToken = await tokenService.getOrRefreshAccessToken();

            if (accessToken) {
                config.headers.Authorization = `Bearer ${accessToken}`;
            }

            return config;
        },
        (error) => {
            throw error;
        }
    );

    client.axiosInstance.interceptors.response.use(
        (response) => {
            try {
                const currentRequest = activeRequests[getRequestId(response)];

                window.clearTimeout(currentRequest?.Timeout);

                endLoader(response, activeRequests);
            } catch (err) {
                endLoader(err, activeRequests);
            }
            return response;
        },
        (error) => {
            endLoader(error, activeRequests);
            throw error;
        }
    );

    return client;
}

export { PearlApps };
