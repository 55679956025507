import { VendorService } from 'vendor/lib/apis/VendorService';
import { SystemService } from 'lib/apis/SystemService';
import { AemService } from '../lib/apis/AemService';
import { TokenService } from '../lib/apis/TokenService';
import { StorageKeys, StorageService } from 'lib/StorageService';

export const initAuthUserStore = async (user) => {
    return new Promise(async (resolve) => {
        const tokenService = TokenService();
        const token = await tokenService.getOrRefreshAccessToken();

        if (token && !user.Id) {
            const systemService = SystemService();
            user.setLoading(true);

            try {
                const authedUser = await systemService.getWhoAmI();
                if (authedUser) {
                    user.setAuthUser(authedUser);
                }
            } finally {
                user.setLoading(false);
            }
        }

        resolve();
    });
};

export const initAppData = async (appData) => {
    return new Promise(async (resolve) => {
        appData.setLoading(true);

        const vendorApi = VendorService();
        const systemApi = SystemService();
        const aemService = AemService();

        const storage = StorageService();

        let categories;
        const cachedCategories = storage.get(StorageKeys.vendorCategories);
        if (cachedCategories) {
            try {
                categories = JSON.parse(cachedCategories);
            } catch (error) {
                console.error('Error parsing cached categories:', error);
                categories = null;
            }
        }

        if (!categories) {
            try {
                categories = await vendorApi.getVendorCategoryList();
                storage.set(StorageKeys.vendorCategories, JSON.stringify(categories));
            } catch (error) {
                console.error('Error fetching vendor categories:', error);
                categories = [];
            }
        }
        let toggles;
        const cachedToggles = storage.get(StorageKeys.featureToggles);
        if (cachedToggles) {
            try {
                toggles = JSON.parse(cachedToggles);
            } catch (error) {
                console.error('Error parsing cached categories:', error);
                toggles = null;
            }
        }

        if (!toggles) {
            try {
                toggles = await systemApi.getFeatureToggles();
                storage.set(StorageKeys.featureToggles, JSON.stringify(toggles));
            } catch (error) {
                console.error('Error fetching vendor categories:', error);
                toggles = [];
            }
        }

        try {
            const [aemUrls, location] = await Promise.all([
                aemService.getRoutes(),
                appData.getCurrentLocation(),
            ]);

            appData.setCategoryList(categories);
            appData.setFeatureToggles(toggles);
            appData.aemUrls = aemUrls || [];
            appData.currentLocation = location;
        } catch (error) {
            console.error('Error fetching app data during initAppData:', error);
        }

        appData.setLoading(false);
        resolve();
    });
};

export const initVendorFAQs = async (vendor) => {
    return new Promise(async (resolve) => {
        vendor.setLoading(true);
        const storage = StorageService();

        const vendorApi = VendorService();
        let VendorFaqOptions;
        const cachedVendorFaqOptions = storage.get(StorageKeys.VendorFaqOptions);
        if (cachedVendorFaqOptions) {
            try {
                VendorFaqOptions = JSON.parse(cachedVendorFaqOptions);
            } catch (error) {
                console.error('Error parsing cached categories:', error);
                VendorFaqOptions = null;
            }
        }

        if (!VendorFaqOptions) {
            try {
                VendorFaqOptions = await vendorApi.getVendorFaqOptions();
                storage.set(StorageKeys.VendorFaqOptions, JSON.stringify(VendorFaqOptions));
            } catch (error) {
                console.error('Error fetching vendor categories:', error);
                VendorFaqOptions = [];
            }
        }
        vendor.setFaqOptions(VendorFaqOptions);

        vendor.setLoading(false);
        resolve();
    });
};
