import styled from 'styled-components';

export default styled.div`
    height: auto;
    width: 100%;

    .boxes {
        display: flex;
        gap: 30px;
        justify-content: center;
        flex-wrap: wrap;
        min-height: fit-content;
    }

    @media (max-width: 1160px) {
        .boxes {
            margin-bottom: 78px;
            flex-grow: 1;
        }
    }

    @media (min-width: 540px) and (max-width: 768px) {
        .boxes {
            padding: 0 80px;
        }
    }

    @media (max-width: 540px) {
        .boxes {
            padding: 0 16px;
        }
    }

    .price-tier-wrapper {
        margin-top: 20px;
        height: 100%;
    }

    .subtitleOne {
        color: #e8184c;
        font-weight: 700;
        font-size: 12px;
        line-height: 18px;
        letter-spacing: 1.5px;
    }

    .pearl-error-SelectedPlan {
        display: flex;
        justify-content: center;
        font-family: 'Objektiv Mk2 Bold';
        font-size: 14px;
        color: #e8184c;
        margin-top: 15px;
    }
`;
