import React from 'react';
import styled from 'styled-components';

const AccountSetupStepCounterStyled = styled.label`
    .steps {
        color: ${(props) => props.theme.colors.primary.base};
    }
    .stepsTitle {
        font-weight: 700;
        font-size: 12px;
        line-height: 18px;
        letter-spacing: 1.5px;
    }
`;

function AccountSetupStepCounter(props) {
    const { stepCountText, stepNameText, hideStepCount } = props;

    return (
        <AccountSetupStepCounterStyled>
            <b className="stepsTitle">
                {!hideStepCount && <span className="steps">{stepCountText}</span>} {stepNameText}
            </b>
        </AccountSetupStepCounterStyled>
    );
}

export { AccountSetupStepCounter };
