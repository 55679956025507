import { RouteGuard } from 'framework';
import { lazy, Suspense } from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import { APP_ROUTES } from 'shared/constants/navigation';
import { WebstoreProvider } from 'shared/contexts/PearlWebstoreContext';
import { ContextRoutingPage } from 'shared/pages/ContextRoutingPage/ContextRoutingPage';
import { DashboardUnauthedPage } from 'shared/pages/DashboardUnauthedPage/DashboardUnauthedPage';
import { InboxUnauthedPage } from 'shared/pages/InboxUnauthedPage/InboxUnauthedPage';
import { EventUserSetupFormsMap } from '../event-user/components/EventUserSetup/EventUserSetupWizard/EventUserSetupWizard';
import { useHrefCatcher } from '../href-catcher';
import { MainLayout } from '../shared/components/MainLayout/MainLayout';
import { OAuthPage } from '../shared/pages/OAuthPage/OAuthPage';
import { EditableSections } from '../vendor/components/Webstore/WebstoreSectionFactories';
import {
    WebstoreSetupFormsMap,
    NewWebstoreSetupFormsMap,
} from '../vendor/components/WebstoreSetup/WebstoreSetupWizard/WebstoreSetupWizard';
import PearlAdminRoutes from './PearlAdminRoutes';
import { RequireAnalytics } from './RequireAnalytics';
import RequireAnonymous from './RequireAnonymous';
import { RequireAuthenticated } from './RequireAuthenticated';
import { RequireEnsureVendor } from './RequireEnsureVendor';
import { RequireEventProfileStarted } from './RequireEventProfileStarted';
import RequireEventUserRole from './RequireEventUserRole';
import { RequireNonVendor } from './RequireNonVendor';
import { RequireNoStripeCustomerId } from './RequireNoStripeCustomerId';
import RequireVendorRole from './RequireVendorRole';
import RequireWebstoreComplete from './RequireWebstoreComplete';
import RequireWebstorePublished from './RequireWebstorePublished';
import { UserContextRouting } from './UserContextRouting';
import { SiteLoader } from '../shared/components/SiteLoader/SiteLoader';
import VendorSignupRedirect from '../event-user/pages/VendorSignupRedirect/VendorSignupRedirect';
import EventUserSignupRedirect from '../event-user/pages/EventUserSignupRedirect/EventUserSignupRedirect';
import { RequireLogin } from './RequireLogin';
import { VendorBasicDetailsForm } from 'vendor/components/VendorBasicDetails/VendorBasicDetailsForm';
import { VendorLoginPage } from 'vendor/components/VendorLogin/VendorLoginPage';
const TermsOfServicePage = lazy(() =>
    import('../vendor/pages/TermsOfServicePage/TermsOfServicePage')
);
const WebstoreSetupPage = lazy(() => import('../vendor/pages/WebstoreSetup/WebstoreSetupPage'));
const NewWebstoreSetupPage = lazy(() =>
    import('../vendor/pages/WebstoreSetup/NewWebstoreSetupPage')
);
const PriceTablePage = lazy(() => import('../vendor/pages/PriceTablePage/PriceTablePage'));
const DashboardPage = lazy(() => import('../vendor/pages/DashboardPage/DashboardPage'));
const AnalyticsPage = lazy(() => import('../vendor/pages/AnalyticsPage/AnalyticsPage'));
const Webstore = lazy(() => import('../vendor/pages/Webstore/Webstore'));
const SectionEditor = lazy(() => import('../vendor/components/Webstore/SectionEdit/SectionEditor'));
const AccountInformationPage = lazy(() =>
    import('../vendor/pages/AccountInformationPage/AccountInformationPage')
);
const AccountSettingsPage = lazy(() =>
    import('../vendor/pages/AccountSettingsPage/AccountSettingsPage')
);
const AccountSubscriptionPage = lazy(() =>
    import('../vendor/pages/AccountSubscriptionPage/AccountSubscriptionPage')
);
const WebstoreManagementPage = lazy(() =>
    import('../vendor/pages/WebstoreManagementPage/WebstoreManagementPage')
);
const ErrorPage = lazy(() => import('../vendor/pages/ErrorPage/ErrorPage'));
const EventUserSetupPage = lazy(() =>
    import('../event-user/pages/EventUserSetupPage/EventUserSetupPage')
);
const VendorListingPage = lazy(() =>
    import('../event-user/pages/VendorListingPage/VendorListingPage')
);

const NationalTravelVendorPage = lazy(() =>
    import('../event-user/pages/NationalTravelVendorPage/NationalTravelListingPage')
);

const VendorDetailsPage = lazy(() =>
    import('../event-user/pages/VendorDetailsPage/VendorDetailsPage')
);

const VendorLandingPage = lazy(() =>
    import('../event-user/pages/VendorLandingPage/VendorLandingPage')
);
const EventUserDashboardPage = lazy(() => import('event-user/pages/DashboardPage/DashboardPage'));
const InboxPage = lazy(() => import('../shared/pages/InboxPage/InboxPage'));
const EnsureVendorPage = lazy(() => import('../shared/pages/EnsureVendorPage/EnsureVendorPage'));
const FeatureTogglePage = lazy(() => import('../shared/pages/FeatureTogglePage/FeatureTogglePage'));
const EventInformationEditPage = lazy(() =>
    import('../event-user/pages/EventInformationEditPage/EventInformationEditPage')
);
const EventAccountInformationPage = lazy(() =>
    import('event-user/pages/AccountInformationPage/AccountInformationPage')
);

const EventAccountSettingsPage = lazy(() =>
    import('event-user/pages/AccountSettingsPage/AccountSettingsPage')
);

const LogoutPage = lazy(() => import('../shared/pages/LogoutPage/LogoutPage'));
const AemRoute = lazy(() => import('../aem/AemRoute'));

const FavoritesPage = lazy(() => import('event-user/pages/FavoritesPage/FavoritesPage'));
const CYBHelpfulInformation = lazy(() =>
    import('../shared/pages/CYBHelpfulInformation/CYBHelpfulInformation')
);
const CYBSendCodeVerify = lazy(() => import('../shared/pages/CYBSendCodeVerify/CYBSendCodeVerify'));
const CYBVerifyYourId = lazy(() => import('../shared/pages/CYBVerifyYourId/CYBVerifyYourId'));
const CYBRequestReceived = lazy(() =>
    import('../shared/pages/CYBRequestReceived/CYBRequestReceived')
);
const CYBContactForm = lazy(() => import('../shared/pages/CYBContactForm/CYBContactForm'));

const VendorCategoriesListingPage = lazy(() =>
    import('../event-user/pages/VendorCategoriesListingPage/VendorCategoriesListingPage')
);

const PearlRoutes = ({ store }) => {
    // Pulling this here cause if its in APP.js it breaks everything
    useHrefCatcher();

    return (
        <Suspense fallback={<SiteLoader />}>
            <Routes>
                {PearlAdminRoutes({ store })}
                <Route path={APP_ROUTES.vendorNew} element={<VendorBasicDetailsForm />} />
                <Route path={APP_ROUTES.vendorLogin} element={<VendorLoginPage />} />
                <Route path={APP_ROUTES.authCallback} element={<OAuthPage />} />
                <Route path={APP_ROUTES.logout} element={<LogoutPage />} />
                <Route
                    path={APP_ROUTES.vendor.root}
                    store={store}
                    element={
                        <RouteGuard guards={[]} context={{ store }}>
                            <ContextRoutingPage
                                eventUserRoute={APP_ROUTES.dashboard}
                                vendorUserRoute={APP_ROUTES.vendor.webstore.root}
                                unAuthRoute={APP_ROUTES.vendor.auth.welcome}
                            />
                        </RouteGuard>
                    }
                />
                {[
                    APP_ROUTES.vendor.auth.login,
                    APP_ROUTES.vendor.auth.welcome,
                    APP_ROUTES.vendor.auth.signup,
                ].map((r) => {
                    return (
                        <Route
                            key={r}
                            path={r}
                            element={
                                <RouteGuard
                                    guards={[
                                        RequireNonVendor,
                                        RequireEnsureVendor,
                                        RequireAnonymous,
                                    ]}
                                    context={{ store }}
                                >
                                    <VendorSignupRedirect />
                                </RouteGuard>
                            }
                        />
                    );
                })}
                {[APP_ROUTES.auth.login, APP_ROUTES.auth.welcome, APP_ROUTES.auth.signup].map(
                    (r) => {
                        return (
                            <Route
                                key={r}
                                path={r}
                                element={
                                    <RouteGuard guards={[RequireAnonymous]} context={{ store }}>
                                        <EventUserSignupRedirect />
                                    </RouteGuard>
                                }
                            />
                        );
                    }
                )}
                <Route
                    path={APP_ROUTES.vendor.ensureVendor}
                    element={
                        // must be auth'd and not a vendor
                        <RouteGuard
                            guards={[RequireAuthenticated, RequireNonVendor]}
                            context={{ store }}
                        >
                            <EnsureVendorPage />
                        </RouteGuard>
                    }
                />
                <Route
                    path={APP_ROUTES.vendor.vendorAgreement}
                    element={<TermsOfServicePage source={APP_ROUTES.vendor.vendorAgreement} />}
                />
                <Route
                    path={APP_ROUTES.vendor.webstore.setup}
                    element={
                        <RouteGuard guards={[RequireVendorRole]} context={{ store }}>
                            <WebstoreSetupPage />
                        </RouteGuard>
                    }
                >
                    {[...WebstoreSetupFormsMap.keys()].map((step) => {
                        return (
                            <Route
                                key={step}
                                path={step}
                                element={WebstoreSetupFormsMap.get(step)}
                                guards={[]}
                            />
                        );
                    })}
                </Route>
                <Route
                    path={APP_ROUTES.vendor.webstore.newsetup}
                    element={
                        <RouteGuard guards={[RequireVendorRole]} context={{ store }}>
                            <NewWebstoreSetupPage />
                        </RouteGuard>
                    }
                >
                    {[...NewWebstoreSetupFormsMap.keys()].map((step) => {
                        return (
                            <Route
                                key={step}
                                path={step}
                                element={NewWebstoreSetupFormsMap.get(step)}
                                guards={[]}
                            />
                        );
                    })}
                </Route>
                <Route path={APP_ROUTES.setup} element={<EventUserSetupPage />}>
                    {[...EventUserSetupFormsMap.keys()].map((step) => {
                        return (
                            <Route
                                key={step}
                                path={step}
                                element={EventUserSetupFormsMap.get(step)}
                                guards={[RequireAuthenticated]}
                            />
                        );
                    })}
                </Route>
                <Route
                    element={
                        <WebstoreProvider>
                            <MainLayout />
                        </WebstoreProvider>
                    }
                >
                    <Route
                        path={APP_ROUTES.vendor.prices}
                        element={
                            <RouteGuard
                                guards={[
                                    RequireVendorRole,
                                    RequireWebstoreComplete,
                                    RequireNoStripeCustomerId,
                                ]}
                                context={{ store }}
                            >
                                <PriceTablePage />
                            </RouteGuard>
                        }
                    />
                    <Route
                        path={APP_ROUTES.vendor.dashboard}
                        element={
                            <RouteGuard
                                guards={[RequireVendorRole, RequireWebstoreComplete]}
                                context={{ store }}
                            >
                                <DashboardPage />
                            </RouteGuard>
                        }
                    />
                    <Route
                        path={APP_ROUTES.vendor.vendorAnalytics}
                        element={
                            <RouteGuard
                                guards={[
                                    RequireVendorRole,
                                    RequireWebstoreComplete,
                                    RequireWebstorePublished,
                                    RequireAnalytics,
                                ]}
                                context={{ store }}
                            >
                                <AnalyticsPage />
                            </RouteGuard>
                        }
                    />
                    <Route
                        path={APP_ROUTES.vendor.webstore.root}
                        element={
                            <RouteGuard
                                guards={[RequireVendorRole, RequireWebstoreComplete]}
                                context={{ store }}
                            >
                                <Webstore />
                            </RouteGuard>
                        }
                    />
                    <Route element={<SectionEditor />}>
                        {[...EditableSections.keys()].map((key) => (
                            <Route
                                key={key}
                                path={`${APP_ROUTES.vendor.webstore.root}/edit${key}`}
                                element={
                                    <RouteGuard
                                        guards={[RequireVendorRole, RequireWebstoreComplete]}
                                        context={{ store }}
                                    >
                                        {EditableSections.get(key)}
                                    </RouteGuard>
                                }
                            />
                        ))}
                    </Route>
                    <Route
                        path={APP_ROUTES.vendor.account.information}
                        element={
                            <RouteGuard
                                guards={[RequireVendorRole, RequireWebstoreComplete]}
                                context={{ store }}
                            >
                                <AccountInformationPage />
                            </RouteGuard>
                        }
                    />
                    <Route
                        path={APP_ROUTES.vendor.account.settings}
                        element={
                            <RouteGuard
                                guards={[RequireVendorRole, RequireWebstoreComplete]}
                                context={{ store }}
                            >
                                <AccountSettingsPage />
                            </RouteGuard>
                        }
                    />
                    <Route
                        path={APP_ROUTES.vendor.account.subscriptions}
                        element={
                            <RouteGuard
                                guards={[RequireVendorRole, RequireWebstoreComplete]}
                                context={{ store }}
                            >
                                <AccountSubscriptionPage />
                            </RouteGuard>
                        }
                    />
                    <Route
                        path={APP_ROUTES.vendor.account.webstoreManagement}
                        element={
                            <RouteGuard
                                guards={[RequireVendorRole, RequireWebstoreComplete]}
                                context={{ store }}
                            >
                                <WebstoreManagementPage />
                            </RouteGuard>
                        }
                    />
                    <Route
                        path={APP_ROUTES.vendor.termsOfService}
                        element={
                            <RouteGuard
                                guards={[RequireVendorRole, RequireWebstoreComplete]}
                                context={{ store }}
                            >
                                <TermsOfServicePage source={APP_ROUTES.vendor.termsOfService} />
                            </RouteGuard>
                        }
                    />
                    <Route path={APP_ROUTES.vendor.webstore.error} element={<ErrorPage />} />
                    <Route path={APP_ROUTES.vendorsWithParams} element={<VendorListingPage />} />
                    <Route path={APP_ROUTES.vendors} element={<VendorListingPage />} />
                    <Route
                        path={APP_ROUTES.nationalTravelVendorsWithParams}
                        element={<NationalTravelVendorPage />}
                    />
                    <Route
                        path={APP_ROUTES.nationalTravelVendors}
                        element={<NationalTravelVendorPage />}
                    />
                    {store.appData.aemUrls &&
                        store.appData.aemUrls.map((url) => (
                            <Route key={url} path={url} element={<AemRoute />} />
                        ))}
                    {[APP_ROUTES.landing, APP_ROUTES.home].map((r) => {
                        return <Route key={r} path={r} element={<VendorLandingPage />} />;
                    })}
                    <Route
                        path={APP_ROUTES.vendorCategoriesListingPage}
                        element={<VendorCategoriesListingPage />}
                    />
                    <Route
                        path={APP_ROUTES.claimBusiness.requestReceived}
                        element={<CYBRequestReceived />}
                    />
                    <Route
                        path={APP_ROUTES.claimBusiness.helpfulInformation}
                        element={<CYBHelpfulInformation />}
                    />
                    <Route
                        path={APP_ROUTES.claimBusiness.sendEmail}
                        element={
                            <RouteGuard guards={[RequireLogin]} context={{ store }}>
                                <CYBSendCodeVerify />
                            </RouteGuard>
                        }
                    />
                    <Route
                        path={APP_ROUTES.claimBusiness.verifyEmail}
                        element={<CYBVerifyYourId />}
                    />
                    <Route path={APP_ROUTES.claimBusiness.claimForm} element={<CYBContactForm />} />
                    <Route path={APP_ROUTES.webstoreUrl} element={<VendorDetailsPage />} />
                    <Route
                        path={APP_ROUTES.inboxUnauthed}
                        element={
                            <RouteGuard guards={[RequireAnonymous]} context={{ store }}>
                                <InboxUnauthedPage />
                            </RouteGuard>
                        }
                    />
                    <Route
                        path={APP_ROUTES.dashboardUnauthed}
                        element={
                            <RouteGuard guards={[RequireAnonymous]} context={{ store }}>
                                <DashboardUnauthedPage />
                            </RouteGuard>
                        }
                    />
                    <Route
                        path={APP_ROUTES.inbox}
                        store={store}
                        element={
                            <UserContextRouting
                                context={{ store }}
                                nonAuthedRoute={APP_ROUTES.inboxUnauthed}
                                routeGuards={[RequireEventUserRole]}
                            />
                        }
                    >
                        <Route
                            store={store}
                            path={APP_ROUTES.inbox}
                            element={
                                <RouteGuard guards={[RequireEventUserRole]} context={{ store }}>
                                    <InboxPage />
                                </RouteGuard>
                            }
                        />
                    </Route>
                    <Route
                        path={APP_ROUTES.vendor.inboxUnauthed}
                        element={
                            <RouteGuard guards={[RequireAnonymous]} context={{ store }}>
                                <InboxUnauthedPage />
                            </RouteGuard>
                        }
                    />
                    <Route
                        store={store}
                        path={APP_ROUTES.vendor.inbox}
                        element={
                            <UserContextRouting
                                context={{ store }}
                                nonAuthedRoute={APP_ROUTES.vendor.inboxUnauthed}
                                routeGuards={[RequireVendorRole]}
                            />
                        }
                    >
                        <Route
                            path={APP_ROUTES.vendor.inbox}
                            element={
                                <RouteGuard guards={[RequireVendorRole]} context={{ store }}>
                                    <InboxPage />
                                </RouteGuard>
                            }
                        />
                    </Route>
                    <Route
                        path={APP_ROUTES.dashboard}
                        store={store}
                        element={
                            <UserContextRouting
                                context={{ store }}
                                nonAuthedRoute={APP_ROUTES.dashboardUnauthed}
                                routeGuards={[RequireEventUserRole, RequireEventProfileStarted]}
                            />
                        }
                    >
                        <Route
                            store={store}
                            path={APP_ROUTES.dashboard}
                            element={
                                <RouteGuard guards={[RequireEventUserRole]} context={{ store }}>
                                    <EventUserDashboardPage />
                                </RouteGuard>
                            }
                        />
                    </Route>
                    <Route
                        path={APP_ROUTES.account.eventInformation}
                        store={store}
                        element={
                            <RouteGuard guards={[RequireEventUserRole]} context={{ store }}>
                                <EventInformationEditPage />
                            </RouteGuard>
                        }
                    />
                    <Route
                        path={APP_ROUTES.account.information}
                        element={
                            <RouteGuard guards={[RequireEventUserRole]} context={{ store }}>
                                <EventAccountInformationPage />
                            </RouteGuard>
                        }
                    />
                    <Route
                        path={APP_ROUTES.account.settings}
                        element={
                            <RouteGuard guards={[RequireEventUserRole]} context={{ store }}>
                                <EventAccountSettingsPage />
                            </RouteGuard>
                        }
                    />
                    <Route
                        path={APP_ROUTES.account.favorites}
                        element={
                            <RouteGuard guards={[RequireEventUserRole]} context={{ store }}>
                                <FavoritesPage />
                            </RouteGuard>
                        }
                    />
                </Route>

                <Route path={'nickcage'} element={<FeatureTogglePage />} />
                <Route path={'*'} element={<Navigate to={'/'} />} />
            </Routes>
        </Suspense>
    );
};

export { PearlRoutes };
