/* eslint-disable complexity */
import { IconSizes, PearlIcon, SolidButton, TextInput } from 'dbi-pearl-ui-kit';
import { PearlError, PhoneNumberInput } from 'framework';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Controller, useFormContext } from 'react-hook-form';
import PasswordValidation from '../VendorBasicDetails/PasswordValidation';
import { useEffect, useState } from 'react';
import TooltipComponent from '../Utility/TooltipComponent/TooltipComponent';
import { ReactComponent as HideView } from '../../../assets/logos/hideView.svg';
import { VendorAuthService } from 'lib/apis/VendorAuthService';

const LoginContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: 0 auto;
    max-width: 500px;
    height: auto;
    position: relative;
    top: 100px;
    padding: 20px;
    box-sizing: border-box;
    border: 1px solid var(--color-gray200);
    box-shadow: rgba(0, 0, 0, 0.04) 0px 4px 8px, rgba(0, 0, 0, 0.06) 0px 0px 2px,
        rgba(0, 0, 0, 0.04) 0px 0px 1px;
    border-radius: 5px;
    .emailStyle {
        width: 100%;
    }
    @media (max-width: 768px) {
        width: 100%;
        position: relative;
        top: 0px;
        height: 100vh;
        display: block;
    }
    .btnStyle {
        margin: 24px 0px;
        width: 100%;
    }
    .inputStyle {
        margin-top: 16px;
        width: 100%;
    }
    .signupBtnStyle {
        width: 100%;
    }
    .isResendDisabled-true {
        color: #717777;
        margin-bottom: 10px;
    }
    .isResendDisabled-false {
        color: #132122;
        cursor: pointer;
        margin-bottom: 10px;
    }
    .resendButton-true {
        display: flex;
        border-bottom: 2px solid #717777;
        width: fit-content;
        margin-top: 16px;
    }
    .resendButton-false {
        display: flex;
        border-bottom: 2px solid #132122;
        width: fit-content;
        margin-top: 16px;
    }
    .infoStyle {
        padding-top: 3.5px;
        padding-left: 5px;
    }
    .emailError {
        color: #de1717;
        margin-top: 10px;
    }
    .eyeIconPosition {
        position: absolute;
        right: 16px;
        top: 16px;
        cursor: pointer;
    }
    .nameStyle {
        display: flex;
        width: 100%;
        margin-top: 16px;
        gap: 16px;
    }
    .firstNameStyle {
        width: 100%;
    }
    .lastNameStyle {
        width: 100%;
    }
    .phoneNumberStyle {
        width: 100%;
        margin-top: 26px;
    }
`;

const Title = styled.h2`
  text-transform: capitalize; 
  font-style: normal
  line-height: 18px;
  letter-spacing: 1.5px;      
  font-size: 12px;
  margin: 0;
  color: #132122;
  width: 100%;
`;

const SubTitle = styled.h1`
    text-transform: capitalize;
    font-size: 32px;
    margin: 0;
    width: 100%;
`;

const Subtitle = styled.p`
    margin-top: 12px;
    font-size: 14px;
    color: #132122;
    font-size: 18px;
    font-style: normal;
    font-weight: 300;
    line-height: 21px;
    width: 100%;
    margin-bottom: 24px;
`;

const Divider = styled.hr`
    width: 100%;
    margin-bottom: 24px;
    margin-top: 10px;
`;

const ResendLink = styled.a`
    display: block;
    font: Objektiv Mk2;
    font-weight: 700;
    font-size: 14px;
    line-height: 15px;
    letter: 1.65px;
    margin-top: 5px;
    text-decoration: none;
`;

const VendorLoginForm = ({
    showPasswordInput,
    showOTPInput,
    showResetPasswordInputs,
    showSignUpButton,
    LoginClickHandler,
    handleOTPSubmit,
    handleSignUp,
    handlePasswordResetSubmit,
    handleLogin,
    showFirstandLastNames,
    showPhoneNumber,
    userFieldsReq,
}) => {
    const { t } = useTranslation();
    const { getValues, setError, watch, setValue, trigger, control } = useFormContext();
    const [timer, setTimer] = useState(60);
    const [isResendDisabled, setIsResendDisabled] = useState(true);
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [passwordStrength, setPasswordStrength] = useState('weak');
    const [emailOTPError, setEmailOTPErrror] = useState('');
    const vendorAuthService = VendorAuthService();
    const [validationStatus, setValidationStatus] = useState({
        length: false,
        uppercaseLowercase: false,
        numberOrSymbol: false,
        noEmailOrName: false,
    });
    const {
        Emailaddress,
        EnterOTP,
        Enterpassword,
        Confirmpassword,
        Firstname,
        Lastname,
        Phonenumber,
    } = getValues();
    const passwordValue = watch('Enterpassword');
    const confirmPasswordValue = watch('Confirmpassword');
    const emailValue = watch('Emailaddress');
    const clickHandler = () => {
        if (!Emailaddress) {
            setError('Emailaddress', {
                type: 'manual',
                message: 'Email address is required',
            });
        } else {
            LoginClickHandler(getValues(), setError);
        }
    };
    const OTPHandler = () => {
        if (!EnterOTP) {
            setError('EnterOTP', {
                type: 'manual',
                message: 'Please enter the OTP',
            });
        } else {
            if (EnterOTP.length === 6) {
                handleOTPSubmit(getValues(), setError);
            }
        }
    };
    const passwordHandler = () => {
        if (passwordValue !== confirmPasswordValue) {
            return;
        }
        if (!Enterpassword) {
            setError('Enterpassword', {
                type: 'manual',
                message: 'Please enter the password',
            });
        }
        if (!Confirmpassword) {
            setError('Confirmpassword', {
                type: 'manual',
                message: 'Please confirm the password',
            });
        }
        if (!Firstname && userFieldsReq.includes('firstName')) {
            setError('Firstname', {
                type: 'manual',
                message: 'Please enter the first name',
            });
        }
        if (!Lastname && userFieldsReq.includes('lastName')) {
            setError('Lastname', {
                type: 'manual',
                message: 'Please enter the last name',
            });
        }
        if (!Phonenumber && userFieldsReq.includes('phoneNumber')) {
            setError('Phonenumber', {
                type: 'manual',
                message: 'Please enter the phone number',
            });
        }
        if (passwordStrength === 'strong') {
            handlePasswordResetSubmit(getValues(), setError);
        }
    };

    useEffect(() => {
        if (confirmPasswordValue) {
            trigger('Confirmpassword');
        }
    }, [passwordValue, confirmPasswordValue, trigger]);

    useEffect(() => {
        if (showOTPInput && isResendDisabled && timer > 0) {
            const countdown = setInterval(() => {
                setTimer((prev) => {
                    if (prev <= 1) {
                        clearInterval(countdown);
                        setIsResendDisabled(false);
                        return 0;
                    }
                    return prev - 1;
                });
            }, 1000);

            return () => clearInterval(countdown);
        }
    }, [isResendDisabled, showOTPInput, timer]);

    useEffect(() => {
        if (emailValue) {
            const lowercaseEmail = emailValue.toLowerCase();
            if (emailValue !== lowercaseEmail) {
                setValue('Emailaddress', lowercaseEmail);
            }
        }
    }, [emailValue, setValue]);
    useEffect(() => {
        // eslint-disable-next-line complexity
        const validatePassword = () => {
            const email = watch('Emailaddress') || '';
            const length = passwordValue?.length >= 8;
            const uppercaseLowercase = /[A-Z]/.test(passwordValue) && /[a-z]/.test(passwordValue);
            const numberOrSymbol =
                /\d/.test(passwordValue) && /[!@#$%^&*(),.?":{}|<>]/.test(passwordValue);
            const noEmailOrName =
                !passwordValue ||
                !email ||
                (passwordValue &&
                    (!email ||
                        !passwordValue.toLowerCase().includes(email.split('@')[0]?.toLowerCase())));
            setValidationStatus({
                length,
                uppercaseLowercase,
                numberOrSymbol,
                noEmailOrName,
            });

            if (length && uppercaseLowercase && numberOrSymbol && noEmailOrName) {
                setPasswordStrength('strong');
            } else if (length && (uppercaseLowercase || numberOrSymbol)) {
                setPasswordStrength('medium');
            } else {
                setPasswordStrength('weak');
            }
        };

        validatePassword();
    }, [passwordValue, watch('Emailaddress')]);

    const togglePasswordVisibility = () => {
        setShowPassword((prevState) => !prevState);
    };
    const toggleConfirmPasswordVisibility = () => {
        setShowConfirmPassword((prevState) => !prevState);
    };

    const handleResendEmailOTP = async () => {
        if (!isResendDisabled) {
            setIsResendDisabled(true);
            setTimer(60);
            setEmailOTPErrror('');

            try {
                await vendorAuthService.resendEmailOTP(Emailaddress);
            } catch (error) {
                setEmailOTPErrror('Failed to resend Email OTP');
                console.error('>> Failed to resend Email OTP:', error);
            }
        }
    };

    return (
        <LoginContainer data-testid="LoginPage-container">
            <Title>{t('Forms.BasicDetails.VendorLoginText.Vendor')}</Title>
            <SubTitle>{t('Forms.BasicDetails.VendorLoginText.WelcomeText')}</SubTitle>
            <Subtitle>{t('Forms.BasicDetails.VendorLoginText.login')}</Subtitle>
            <Divider />
            <div className="emailStyle">
                <TextInput formFieldName="Emailaddress" type="email" />
                <PearlError name="Emailaddress" />
            </div>
            {showPasswordInput && (
                <>
                    <div className="inputStyle" style={{ position: 'relative' }}>
                        <TextInput
                            formFieldName="Password"
                            type={showPassword ? 'text' : 'password'}
                        />
                        <PearlError name="Password" />
                        {showPassword && (
                            <PearlIcon
                                iconName="view"
                                size={IconSizes.MD}
                                onClick={togglePasswordVisibility}
                                className="eyeIconPosition"
                            />
                        )}
                        {!showPassword && (
                            <HideView
                                onClick={togglePasswordVisibility}
                                className="eyeIconPosition"
                            />
                        )}
                    </div>
                    <SolidButton
                        className="btnStyle"
                        type="submit"
                        onClick={() => handleLogin(getValues())}
                    >
                        {t('Forms.BasicDetails.VendorLoginText.Login')}
                    </SolidButton>
                </>
            )}
            {showOTPInput && (
                <>
                    <div className="inputStyle">
                        <TextInput formFieldName="EnterOTP" />
                        <PearlError name="EnterOTP" />
                        {emailOTPError && (
                            <div data-testid="emailError" className="emailError">
                                {emailOTPError}
                            </div>
                        )}
                        <div className={`resendButton-${isResendDisabled}`}>
                            <ResendLink
                                className={`isResendDisabled-${isResendDisabled}`}
                                onClick={handleResendEmailOTP}
                            >
                                {t('Forms.BasicDetails.VerificationModalContent.ResendOTP')}
                            </ResendLink>
                            <TooltipComponent
                                header={''}
                                showCloseButton={false}
                                width={'291'}
                                height={'95'}
                                bottom={'132'}
                                left={'570'}
                                bottomSmall={'135'}
                                leftSmall={'545'}
                                arrowBottom={'-22'}
                                arrowLeft={'13'}
                                content={t(
                                    'Forms.BasicDetails.VerificationModalContent.TooltipContent'
                                )}
                            />
                        </div>
                    </div>
                    <SolidButton
                        className="btnStyle"
                        type="submit"
                        onClick={(e) => {
                            e.preventDefault();
                            OTPHandler(getValues());
                        }}
                    >
                        {t('Forms.BasicDetails.VendorLoginText.Submit')}
                    </SolidButton>
                </>
            )}
            {showSignUpButton && (
                <SolidButton className="btnStyle" onClick={handleSignUp}>
                    {t('Forms.BasicDetails.VendorLoginText.Signup')}
                </SolidButton>
            )}
            {showResetPasswordInputs && (
                <>
                    {showFirstandLastNames && (
                        <div className="nameStyle">
                            <div className="firstNameStyle">
                                <TextInput formFieldName="Firstname" />
                                <PearlError name="Firstname" />
                            </div>
                            <div className="lastNameStyle">
                                <TextInput formFieldName="Lastname" />
                                <PearlError name="Lastname" />
                            </div>
                        </div>
                    )}
                    {showPhoneNumber && (
                        <div className="phoneNumberStyle">
                            <Controller
                                data-testid="Phonenumber"
                                name={'Phonenumber'}
                                control={control}
                                render={({ field }) => (
                                    <PhoneNumberInput
                                        field={field}
                                        className="custom-phone-input"
                                    />
                                )}
                            />
                            <PearlError name="Phonenumber" />
                        </div>
                    )}
                    <div className="inputStyle" style={{ position: 'relative' }}>
                        <TextInput
                            type={showPassword ? 'text' : 'password'}
                            formFieldName="Enterpassword"
                        />
                        <PearlError name="Enterpassword" />
                        {showPassword && (
                            <PearlIcon
                                iconName="view"
                                size={IconSizes.MD}
                                onClick={togglePasswordVisibility}
                                className="eyeIconPosition"
                            />
                        )}
                        {!showPassword && (
                            <HideView
                                onClick={togglePasswordVisibility}
                                className="eyeIconPosition"
                            />
                        )}
                        {passwordValue && (
                            <PasswordValidation
                                passwordStrength={passwordStrength}
                                validationStatus={validationStatus}
                            />
                        )}
                    </div>
                    <div className="inputStyle" style={{ position: 'relative' }}>
                        <TextInput
                            type={showConfirmPassword ? 'text' : 'password'}
                            formFieldName="Confirmpassword"
                        />
                        <PearlError name="Confirmpassword" />
                        {showConfirmPassword && (
                            <PearlIcon
                                iconName="view"
                                size={IconSizes.MD}
                                onClick={toggleConfirmPasswordVisibility}
                                className="eyeIconPosition"
                            />
                        )}
                        {!showConfirmPassword && (
                            <HideView
                                onClick={toggleConfirmPasswordVisibility}
                                className="eyeIconPosition"
                            />
                        )}
                    </div>
                    <SolidButton className="btnStyle" onClick={() => passwordHandler(getValues())}>
                        {t('Forms.BasicDetails.VendorLoginText.Submit')}
                    </SolidButton>
                </>
            )}
            {!showOTPInput && !showResetPasswordInputs && !showPasswordInput && !showSignUpButton && (
                <>
                    <SolidButton className="btnStyle" onClick={clickHandler}>
                        {t('Forms.BasicDetails.VendorLoginText.Login')}
                    </SolidButton>
                </>
            )}
        </LoginContainer>
    );
};

export default VendorLoginForm;
