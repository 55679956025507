import * as Yup from 'yup';
import i18n from 'i18n';

const PickYourPlanSchemaWrapper = () => {
    const PickYourPlanSchema = Yup.object()
        .shape({
            SelectedPlan: Yup.string()
                .nullable()
                .required(
                    i18n.t('Forms.Validation.FieldRequired', {
                        field: i18n.t('Forms.Labels.Plan'),
                    })
                ),
        })
        .required();
    return PickYourPlanSchema;
};

export { PickYourPlanSchemaWrapper };
