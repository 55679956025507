import React, { useEffect } from 'react';
import VendorAccountSetupStepLayoutStyled from './VendorAccountSetupStepLayout.styled';
import { newPageTrigger } from 'LivePerson';

function VendorAccountSetupStepLayout(props) {
    const { header, formComponent, footer } = props;

    useEffect(() => {
        newPageTrigger(window.location.href);
    }, [window.location.href]);

    return (
        <VendorAccountSetupStepLayoutStyled>
            <div className="rightSide">
                <div className="innerRight">
                    <div className="stepHeader">{header}</div>
                    <main className="stepComponent">
                        <div className="formWrapper">{formComponent}</div>
                    </main>
                </div>
                {footer}
            </div>
        </VendorAccountSetupStepLayoutStyled>
    );
}

export { VendorAccountSetupStepLayout };
