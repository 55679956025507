import * as Yup from 'yup';
import i18n from 'i18n';

const vendorBusinessDetailsFormSchemaWrapper = (isBusinessName) => {
    const vendorBusinessDetailsFormSchema = Yup.object()
        .shape({
            WebstoreName: Yup.string()
                .max(
                    120,
                    i18n.t('Forms.Validation.CannotExceedCharacterLimit', {
                        characterLimit: '120',
                    })
                )
                .matches(
                    /^(?!\s)(?!.*\s$)/,
                    i18n.t('Forms.BusinessInformation.WebstoreNameValidation')
                )
                .nullable()
                .required(
                    i18n.t('Forms.Validation.FieldRequired', {
                        field: isBusinessName
                            ? i18n.t('Forms.EditBasics.BusinessNameLabel')
                            : i18n.t('Forms.Labels.WebstoreName'),
                    })
                ),
        })
        .required();

    return vendorBusinessDetailsFormSchema;
};

export { vendorBusinessDetailsFormSchemaWrapper };
