import { PearlApiClient } from 'framework';
import { getAppName } from 'shared/hooks/AppName';

export function VendorAuthService() {
    const appName = getAppName();
    const api = PearlApiClient(appName);

    const signUpVendor = async (email, password, phoneNumber, givenName, familyName) => {
        const res = await api.post('/auth/signup', {
            email,
            password,
            phoneNumber,
            givenName,
            familyName,
        });
        return new Promise((resolve, reject) => {
            if (res?.ok) {
                resolve(res);
            } else {
                reject(res?.data?.error);
            }
        });
    };

    const confirmVendor = async (identifier) => {
        const res = await api.post('/auth/confirm-user', { identifier });
        return new Promise((resolve, reject) => {
            if (res?.ok) {
                resolve(res);
            } else {
                reject(res?.data?.error);
            }
        });
    };

    const verifyToken = async (idToken) => {
        const res = await api.post('/auth/verify-token', { idToken });
        return new Promise((resolve, reject) => {
            if (res?.ok) {
                resolve(res);
            } else {
                reject(res?.data?.error);
            }
        });
    };

    const verifyEmailOTP = async ({ email, otp, newOrOld }) => {
        const res = await api.post('/auth/verify-email', { email, otp, newOrOld });
        return new Promise((resolve, reject) => {
            if (res?.ok) {
                resolve(res);
            } else {
                reject(res?.data?.error);
            }
        });
    };

    const respondToMFA = async ({ username, mfaCode, phoneNumber, session, type }) => {
        const res = await api.post('/auth/respond-to-mfa', {
            username,
            mfaCode,
            phoneNumber,
            session,
            type,
        });
        return new Promise((resolve, reject) => {
            if (res?.ok) {
                resolve(res);
            } else {
                reject(res.data.error);
            }
        });
    };

    const resendEmailOTP = async (email) => {
        const res = await api.post('/auth/resend-email-otp', { email });
        return new Promise((resolve, reject) => {
            if (res?.ok) {
                resolve(res);
            } else {
                reject(res);
            }
        });
    };

    const loginVendor = async ({ identifier, password }) => {
        const res = await api.post('/auth/login', { identifier, password });
        return new Promise((resolve, reject) => {
            if (res?.ok) {
                resolve(res);
            } else {
                reject(res?.data?.error);
            }
        });
    };

    const refreshToken = async ({ refreshToken, username }) => {
        const res = await api.post('/auth/refresh-token', { refreshToken, username });
        return new Promise((resolve, reject) => {
            if (res?.ok) {
                resolve(res);
            } else {
                reject(res?.data?.error);
            }
        });
    };

    const logoutVendor = async (accessToken) => {
        const res = await api.post('/auth/logout', { accessToken });
        return new Promise((resolve, reject) => {
            if (res?.ok) {
                resolve(res);
            } else {
                reject(res?.data?.error);
            }
        });
    };

    const getVendor = async (username) => {
        const res = await api.get(`/auth/user-details/${username}`);
        return new Promise((resolve, reject) => {
            if (res?.ok) {
                resolve(res);
            } else {
                reject(res?.data?.error);
            }
        });
    };

    const checkEmailAvailability = async (email) => {
        const res = await api.post('/auth/check-email-availability', { email });

        return new Promise((resolve, reject) => {
            if (res?.ok) {
                resolve(res);
            } else {
                console.log(res?.data?.error, res?.data);
                reject(res?.data?.error);
            }
        });
    };

    const createUser = async (email, password, firstName, lastName, phoneNumber) => {
        const res = await api.post('/auth/create-user', {
            email,
            password,
            firstName,
            lastName,
            phoneNumber,
        });
        return new Promise((resolve, reject) => {
            if (res?.ok) {
                resolve(res);
            } else {
                reject(res?.data?.error);
            }
        });
    };

    const forgotPassword = async (email) => {
        const res = await api.post('/auth/forgot-password', { email });
        return new Promise((resolve, reject) => {
            if (res?.ok) {
                resolve(res);
            } else {
                reject(res?.data?.error);
            }
        });
    };

    const verifyCodeForgotPassword = async (email, verificationCode) => {
        const res = await api.post('/auth/verify-code', { email, verificationCode });
        return new Promise((resolve, reject) => {
            if (res?.ok) {
                resolve(res);
            } else {
                reject(res?.data?.error);
            }
        });
    };

    const resetPassword = async (email, password) => {
        const res = await api.post('/auth/reset-password', { email, password });
        return new Promise((resolve, reject) => {
            if (res?.ok) {
                resolve(res);
            } else {
                reject(res?.data?.error);
            }
        });
    };

    return {
        signUpVendor,
        confirmVendor,
        verifyToken,
        verifyEmailOTP,
        respondToMFA,
        resendEmailOTP,
        loginVendor,
        refreshToken,
        logoutVendor,
        getVendor,
        checkEmailAvailability,
        createUser,
        forgotPassword,
        verifyCodeForgotPassword,
        resetPassword,
        axiosInstance: api.axiosInstance,
    };
}
