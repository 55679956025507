import { useTranslation } from 'react-i18next';
import { ReturnToBusinessLocationsButton } from './ReturnToBusinessLocationsButton';
import { AccountSetupStepCounter } from '../../../framework';
import React from 'react';
import styled from 'styled-components';

const NewVendorWebstoreSetupHeader = (props) => {
    const { onReturnToBusinessLocations, stepName, currentStepCount, totalSteps, hideStepCount } =
        props;

    const { t } = useTranslation();
    const stepTns = t(`Forms.${stepName}`, { returnObjects: true });

    const Divider = styled.hr`
        border: none;
        border-top: 2px solid #cfd0d0;
        margin: 32px 0;
    `;

    return (
        <>
            <div className="actions">
                {!!onReturnToBusinessLocations && (
                    <ReturnToBusinessLocationsButton onClick={onReturnToBusinessLocations} />
                )}
            </div>
            <AccountSetupStepCounter
                stepCountText={`STEP ${t('Forms.Labels.Steps', {
                    completedSteps: currentStepCount,
                    totalSteps: totalSteps,
                })}`}
                stepNameText={stepTns.StepName}
                hideStepCount={hideStepCount}
            />
            <h1 className="title">{stepTns.Title}</h1>
            <p className="subtitle">{stepTns.Subtitle}</p>
            {!!stepTns.Requirement && (
                <div className="stepInstructions">
                    <label className="cta">
                        <b>{stepTns.Requirement}</b>
                    </label>
                </div>
            )}
            <Divider />
        </>
    );
};

export { NewVendorWebstoreSetupHeader };
