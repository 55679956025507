import React, { useState } from 'react';
import PickYourPlanSectionStyled from './PickYourPlanSection.styled';
import { useTranslation } from 'react-i18next';
import { WebstorePlanTiers, PickYourPlanTile, PearlError } from 'framework';
import { useFormContext } from 'react-hook-form';

const tierSelections = [
    {
        id: WebstorePlanTiers.STARTER.ID,
        value: WebstorePlanTiers.STARTER.VALUE,
        title: 'Forms.PlanTier.PlanTitle.Starter',
        period: 'Forms.PlanTier.Period',
        altPrice: 'Forms.PlanTier.AltPrice.Starter',
        description: 'Forms.PlanTier.Description.Starter',
        benefits: 'Forms.PlanTier.Benefits.Starter',
        isPopular: 'Forms.PlanTier.Popular.Starter',
        planInfoBtn: 'Forms.PlanTier.PlanInfoButton.Starter',
    },
    {
        id: WebstorePlanTiers.ESSENTIAL.ID,
        value: WebstorePlanTiers.ESSENTIAL.VALUE,
        title: 'Forms.PlanTier.PlanTitle.Essential',
        period: 'Forms.PlanTier.Period',
        altPrice: 'Forms.PlanTier.AltPrice.Essential',
        altPriceLink: 'Forms.PlanTier.AltPriceLink.Essential',
        description: 'Forms.PlanTier.Description.Essential',
        benefits: 'Forms.PlanTier.Benefits.Essential',
        isPopular: 'Forms.PlanTier.Popular.Essential',
        planInfoBtn: 'Forms.PlanTier.PlanInfoButton.Essential',
    },
    {
        id: WebstorePlanTiers.PREMIUM.ID,
        value: WebstorePlanTiers.PREMIUM.VALUE,
        title: 'Forms.PlanTier.PlanTitle.Premium',
        period: 'Forms.PlanTier.Period',
        altPrice: 'Forms.PlanTier.AltPrice.Premium',
        altPriceLink: 'Forms.PlanTier.AltPriceLink.Premium',
        description: 'Forms.PlanTier.Description.Premium',
        benefits: 'Forms.PlanTier.Benefits.Premium',
        isPopular: 'Forms.PlanTier.Popular.Premium',
        planInfoBtn: 'Forms.PlanTier.PlanInfoButton.Premium',
    },
];

const PickYourPlanSection = ({ defaultPlanValue }) => {
    const formFieldName = 'SelectedPlan';
    const { getValues } = useFormContext();
    const SelectedPlan = getValues('SelectedPlan');

    const { t } = useTranslation();

    const [selectedPlan, setSelectedPlan] = useState(
        defaultPlanValue ? defaultPlanValue : WebstorePlanTiers.ESSENTIALVALUE
    );
    const [expandedPlan, setExpandedPlan] = useState(null);

    const handleToggleClick = (id) => {
        setExpandedPlan(expandedPlan === id ? null : id);
    };

    return (
        <PickYourPlanSectionStyled>
            <label className="subtitleOne">{t('Forms.PlanTier.SubtitleOne')}</label>
            {!SelectedPlan && <PearlError name="SelectedPlan"></PearlError>}
            <div className="price-tier-wrapper">
                <div className="boxes">
                    {tierSelections.map((tier) => {
                        const benefits = t(tier.benefits, {
                            returnObjects: true,
                        });
                        return (
                            <PickYourPlanTile
                                title={t(tier.title)}
                                price={`$${t(tier.value)}${t(tier.period)}`}
                                period={t(tier.period)}
                                value={t(tier.value)}
                                altPrice={t(tier.altPrice)}
                                altPriceLink={t(tier.altPriceLink)}
                                description={t(tier.description)}
                                benefits={benefits}
                                isPopular={t(tier.isPopular)}
                                key={tier.id}
                                planId={tier.id}
                                formFieldName={formFieldName}
                                onClick={() => setSelectedPlan(tier.value)}
                                isSelected={selectedPlan === tier.value}
                                isOpen={expandedPlan === tier.id}
                                onToggleClick={() => handleToggleClick(tier.id)}
                                planInfoBtn={t(tier.planInfoBtn)}
                            />
                        );
                    })}
                </div>
            </div>
        </PickYourPlanSectionStyled>
    );
};

export default PickYourPlanSection;
