import styled from 'styled-components';

const SocialLinksSectionStyled = styled.div`
    height: auto;
    .divider-container {
        display: none;
    }

    .link-row {
        display: flex;
        position: relative;
    }
    .tooltipWebsite {
        display: inline-flex;
        position: absolute !important;
        right: 16px;
        top: 16px;
        .icon-info {
            width: 18px;
            height: 18px;
            font-size: 18px;
            &:before {
                width: 18px !important;
                height: 18px !important;
            }
        }
    }
`;

export { SocialLinksSectionStyled };
