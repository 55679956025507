const WebstorePlanTiers = {
    STARTER: {
        VALUE: '0.00',
        ID: 'price_1LUXsEGo89uVbJToVIyYCF3V',
    },
    ESSENTIAL: {
        VALUE: '49',
        ID: 'price_1Mr4lJGo89uVbJTongDW5eIl',
    },
    PREMIUM: {
        VALUE: '119',
        ID: 'price_1MZeNuGo89uVbJToNd7A7Q1Q',
    },
};

function getPlanIdByAmount(amount) {
    for (const plan in WebstorePlanTiers) {
        if (WebstorePlanTiers[plan].VALUE === amount) {
            return WebstorePlanTiers[plan].ID;
        }
    }
    return null; // Return null if no matching amount is found
}

export { WebstorePlanTiers, getPlanIdByAmount };
