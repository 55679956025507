import { useTranslation } from 'react-i18next';
import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { MobileFooterNavigationStyled } from './MobileFooterNavigation.styled';
import { IconName, IconSizes, PearlIcon } from 'dbi-pearl-ui-kit';
import { APP_ROUTES } from 'shared/constants/navigation';
import { useAuthUserContext } from 'contexts/AuthUserContext';
import { usePearlModal, useIsScreenLarge } from 'framework';
import { openUserChoiceLoginModal } from '../UserChoiceLogin/UserChoiceLogin';
import { AuthType } from 'shared/constants/authType';

const MobileFooterNavigation = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const { t } = useTranslation();
    const { openModal, closeModal } = usePearlModal();
    const isScreenLarge = useIsScreenLarge();

    const checkForActive = (linkSrc) => {
        return `${location?.pathname}` === linkSrc ? 'active' : '';
    };

    const { authUser } = useAuthUserContext();
    const [inboxLink, setInboxLink] = useState('');
    const [dashboardLink, setDashboardLink] = useState('');
    const [homeLink, setHomeLink] = useState('');
    const [accountSettingsLink, setAccountSettingsLink] = useState('');

    useEffect(() => {
        authUser.isAuthedVendor
            ? setInboxLink(APP_ROUTES.vendor.inbox)
            : setInboxLink(APP_ROUTES.inbox);
        authUser.isAuthedVendor
            ? setDashboardLink(APP_ROUTES.vendor.dashboard)
            : setDashboardLink(APP_ROUTES.dashboard);
        !authUser.isAuthedEventUser && !authUser.isAuthedVendor
            ? setHomeLink(APP_ROUTES.landing)
            : setHomeLink('');
        authUser.isAuthedVendor
            ? setAccountSettingsLink(APP_ROUTES.vendor.account.information)
            : setAccountSettingsLink(APP_ROUTES.account.information);
    }, [authUser]);

    return (
        <MobileFooterNavigationStyled id="mobile-footer-navigation">
            <div className="icons">
                <div>
                    {authUser.isAuthedVendor && (
                        <button
                            onClick={() => navigate(APP_ROUTES.vendor.webstore.root)}
                            className={checkForActive(APP_ROUTES.vendor.webstore.root)}
                        >
                            <PearlIcon iconName={IconName.STORE} size={IconSizes.LG} />
                            <p className="p4">{t('Navigation.EventMobileFooterBar.Webstore')}</p>
                        </button>
                    )}
                    {!authUser.isAuthedVendor && (
                        <button
                            onClick={() => navigate(APP_ROUTES.vendors)}
                            className={checkForActive(APP_ROUTES.vendors)}
                        >
                            <PearlIcon iconName={IconName.SEARCH} size={IconSizes.LG} />
                            <p className="p4">{t('Navigation.EventMobileFooterBar.Vendors')}</p>
                        </button>
                    )}
                </div>
                <div>
                    <button
                        onClick={() =>
                            !authUser.Id
                                ? openUserChoiceLoginModal(openModal, closeModal, isScreenLarge)
                                : navigate(inboxLink)
                        }
                        className={checkForActive(inboxLink)}
                    >
                        <PearlIcon iconName={IconName.EMAIL} size={IconSizes.LG} />
                        <p className="p4">{t('Navigation.EventMobileFooterBar.Inbox')}</p>
                    </button>
                </div>
                <div>
                    {homeLink && (
                        <button
                            onClick={() => navigate(homeLink)}
                            className={checkForActive(homeLink)}
                        >
                            <PearlIcon iconName={IconName.DASHBOARD_OUTLINE} size={IconSizes.LG} />
                            <p className="p4">{t('Navigation.EventMobileFooterBar.Home')}</p>
                        </button>
                    )}
                    {!homeLink && (
                        <button
                            onClick={() => navigate(dashboardLink)}
                            className={checkForActive(dashboardLink)}
                        >
                            <PearlIcon iconName={IconName.DASHBOARD_OUTLINE} size={IconSizes.LG} />
                            <p className="p4">{t('Navigation.EventMobileFooterBar.Dashboard')}</p>
                        </button>
                    )}
                </div>
                {authUser.isAuthedVendor && (
                    <div>
                        <button
                            onClick={() => navigate(APP_ROUTES.vendor.webstore.editAvailability)}
                            className={checkForActive(APP_ROUTES.vendor.webstore.editAvailability)}
                        >
                            <PearlIcon iconName={IconName.CALENDAR} size={IconSizes.LG} />
                            <p className="p4">{t('Navigation.EventMobileFooterBar.Calendar')}</p>
                        </button>
                    </div>
                )}
                {/* always display if a vendor is not signed in */}
                {!authUser.isAuthedVendor && (
                    <div>
                        <button
                            onClick={() => navigate(APP_ROUTES.planningToolkit)}
                            className={checkForActive(APP_ROUTES.planningToolkit)}
                        >
                            <PearlIcon iconName={IconName.PLANNING} size={IconSizes.LG} />
                            <p className="p4">{t('Navigation.EventMobileFooterBar.Planning')}</p>
                        </button>
                    </div>
                )}
                {/* display if any user logged in - settings link dynamic based off user type */}
                {!!authUser.Id && (
                    <div>
                        <button
                            onClick={() => navigate(accountSettingsLink)}
                            className={checkForActive(accountSettingsLink)}
                        >
                            <PearlIcon iconName={IconName.USER} size={IconSizes.LG} />
                            <p className="p4">{t('Navigation.EventMobileFooterBar.Account')}</p>
                        </button>
                    </div>
                )}
                {/* display if no user logged in */}
                {!authUser.Id && (
                    <div>
                        <button
                            onClick={() =>
                                openUserChoiceLoginModal(
                                    openModal,
                                    closeModal,
                                    isScreenLarge,
                                    AuthType.signup
                                )
                            }
                            className={checkForActive(APP_ROUTES.auth.login)}
                            type="button"
                        >
                            <PearlIcon iconName={IconName.USER} size={IconSizes.LG} />
                            <p className="p4">{t('Navigation.EventMobileFooterBar.Login')}</p>
                        </button>
                    </div>
                )}
            </div>
            <div className="bumper"></div>
        </MobileFooterNavigationStyled>
    );
};

export { MobileFooterNavigation };
