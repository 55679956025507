import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';

const ModalInput = styled.div`
    width: 432px;
    height: 52px;
    position: relative;
    @media (max-width: 768px) {
        width: 100%;
    }
    margin-bottom: 16px;
    border: 1px solid #ccc;
    .label {
        position: absolute;
        top: -10%;
        left: 4%;
        font-size: 12px;
        text-transform: none;
        line-height: 15px;
        color: #424d4e;
        background: white;
        padding: 0 5px;
        display: ${({ showLabel }) => (showLabel ? 'inline' : 'none')};
    }
`;

const OTPInput = ({ length = 6, values, setValues }) => {
    const inputRef = useRef(null);
    useEffect(() => {
        // Automatically focus the input field on component mount
        if (inputRef.current) {
            inputRef.current.focus();
        }
    }, []);

    const handleChange = (e) => {
        const value = e.target.value;

        // Allow only numeric values and limit the length to `length`
        if (/^[0-9]*$/.test(value)) {
            setValues(value.slice(0, length)); // Limit input length
        } else if (value === '') {
            setValues('');
        }
    };

    return (
        <ModalInput showLabel>
            <span className="label">Code</span>
            <input
                ref={inputRef}
                type="text"
                data-testid={`otp-input-id`}
                value={values}
                onChange={handleChange}
                maxLength={length} // Ensure the input cannot exceed the length
                style={{
                    width: '95%',
                    height: '40px',
                    margin: 'auto',
                    textAlign: 'center',
                    fontSize: '26px',
                    border: '0px solid #ccc',
                    outline: 'none',
                    paddingTop: '5px',
                    letterSpacing: '13px',
                }}
                className="otpInput"
            />
        </ModalInput>
    );
};

export default OTPInput;
