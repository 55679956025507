import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import OTPInput from './OTPInput';
import { useTranslation } from 'react-i18next';
import { IconSizes, PearlIcon, SolidButton } from 'dbi-pearl-ui-kit';
import { VendorAuthService } from 'lib/apis/VendorAuthService';
import TooltipComponent from '../Utility/TooltipComponent/TooltipComponent';

const Container = styled.div`
    padding: 16px;
    width: 100%;
    .infoStyle {
        padding-top: 2.5px;
        padding-left: 5px;
    }
    .btnStyle {
        color: #fff;
        padding: 12px;
        font-size: 16px;
        border: none;
        border-radius: 4px;
        cursor: pointer;
        width: calc(100% - 32px); /* Adjusting width based on padding */
        position: fixed;
        bottom: 16px;
        left: 50%;
        transform: translateX(-50%);
        button {
            width: 100%;
        }
    }
    .labelStyle {
        color: #e8184c;
        font-family: 'Objektiv Mk2 Bold';
        font-size: 11px;
        font-style: normal;
        font-weight: 700;
        line-height: 15px;
        letter-spacing: 1.65px;
        text-transform: uppercase;
        padding-top: 6px;
    }
    .iconStyle {
        color: #132122;
    }
    .textStyle {
        margin-bottom: 16px;
    }
    .resendButton-true {
        display: flex;
        border-bottom: 2px solid #717777;
        width: fit-content;
    }
    .resendButton-false {
        display: flex;
        border-bottom: 2px solid #132122;
        width: fit-content;
    }
    .isResendDisabled-true {
        color: #717777;
        margin-bottom: 10px;
    }
    .isResendDisabled-false {
        color: #132122;
        cursor: pointer;
        margin-bottom: 10px;
    }
    .errorStyle {
        color: #de1717;
        margin-bottom: 10px;
    }
    .dividerStyle {
        margin-bottom: 32px;
    }
    .iconWithLabel {
        display: flex;
        margin-bottom: 16px;
        gap: 16px;
    }
`;

const Header = styled.h1`
    color: #132122;
    text-transform: none;
    font-size: 24px;
`;

const Section = styled.div`
    margin-bottom: 24px;
    padding-right: 10px;
`;

const ResendText = styled.a`
    display: block;
    font: Objektiv Mk2;
    font-weight: 700;
    font-size: 14px;
    line-height: 15px;
    letter: 1.65px;
    margin-top: 5px;
    color: #cfd0d0;
    text-decoration: none;
    text-underline-offset: 10px;
`;

const OTP_LENGTH = 6;

const MobileVerificationPage = ({ email, onVerify }) => {
    const vendorAuthService = VendorAuthService();
    const [timer, setTimer] = useState(60);
    const { t } = useTranslation();
    const [emailOtpValues, setEmailOtpValues] = useState('');
    const [isButtonDisabled, setIsButtonDisabled] = useState(true);
    const [emailOTPError, setEmailOTPErrror] = useState('');
    const [isResendDisabled, setIsResendDisabled] = useState(true);

    useEffect(() => {
        const isEmailFilled = emailOtpValues.length === 6;
        setIsButtonDisabled(!isEmailFilled);
    }, [emailOtpValues]);

    useEffect(() => {
        if (isResendDisabled && timer > 0) {
            const countdown = setInterval(() => {
                setTimer((prev) => {
                    if (prev <= 1) {
                        clearInterval(countdown);
                        setIsResendDisabled(false);
                        return 0;
                    }
                    return prev - 1;
                });
            }, 1000);

            return () => clearInterval(countdown);
        }
    }, [isResendDisabled, timer]);

    const handleResendEmailOTP = async () => {
        if (!isResendDisabled) {
            setIsResendDisabled(true);
            setTimer(60);
            setEmailOTPErrror('');
            setEmailOtpValues('');

            try {
                await vendorAuthService.resendEmailOTP(email);
            } catch (error) {
                setEmailOTPErrror('Failed to resend Email OTP');
                console.error('>> Failed to resend Email OTP:', error);
            }
        }
    };

    const handleVerifyBtn = async () => {
        try {
            const results = await vendorAuthService.verifyEmailOTP({
                email,
                otp: emailOtpValues,
            });
            if (onVerify && results?.ok) {
                onVerify();
            }
        } catch (e) {
            console.error('Email OTP verification failed');
            setEmailOTPErrror(e);
        }
    };

    return (
        <Container data-testid="MobileOTP-container">
            <Header>{t('Forms.BasicDetails.VerificationModalContent.Title')}</Header>
            <hr className="dividerStyle"></hr>
            <Section>
                <div className="iconWithLabel">
                    <PearlIcon className="iconStyle" iconName="email" size={IconSizes.LG} />
                    <div className="labelStyle">
                        <b>{t('Forms.BasicDetails.VerificationModalContent.EmailCode')}</b>
                    </div>
                </div>
                <div className="textStyle">
                    {t('Forms.BasicDetails.VerificationModalContent.EnterEmailCode') + email}
                </div>
                <OTPInput
                    length={OTP_LENGTH}
                    values={emailOtpValues}
                    setValues={setEmailOtpValues}
                />
                {emailOTPError && <div className="errorStyle">{emailOTPError}</div>}
                <div className={`resendButton-${isResendDisabled}`}>
                    <ResendText
                        className={`isResendDisabled-${isResendDisabled}`}
                        onClick={handleResendEmailOTP}
                    >
                        {t('Forms.BasicDetails.VerificationModalContent.ResendOTP')}
                    </ResendText>
                    <TooltipComponent
                        header={''}
                        showCloseButton={false}
                        width={'291'}
                        height={'95'}
                        bottomSmall={'130'}
                        leftSmall={'608'}
                        arrowBottomSmallscreen={'-24'}
                        arrowLeftSmallscreen={'11'}
                        content={t('Forms.BasicDetails.VerificationModalContent.TooltipContent')}
                    />
                </div>
            </Section>

            <div className="btnStyle">
                <SolidButton disabled={isButtonDisabled} onClick={handleVerifyBtn}>
                    {t('Forms.BasicDetails.VerificationModalContent.Verify')}
                </SolidButton>
            </div>
        </Container>
    );
};

export default MobileVerificationPage;
