/* eslint-disable no-console, no-unused-vars */
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
    COMPLETE_STEP,
    NEW_DEFAULT_STEP,
    WebstoreSetupStepsMap,
    NewWebstoreSetupStepForms,
} from 'vendor/constants/WebstoreSetupSteps';
import { WebstoreService } from 'vendor/lib/apis/WebstoreService';
import { useWebstoreContext } from 'vendor/contexts/WebstoreContext/WebstoreContext';
import { useVendorContext } from 'vendor/contexts/VendorContext';
import { APP_ROUTES } from 'shared/constants/navigation';

const WebstoreSetupContext = React.createContext();
WebstoreSetupContext.displayName = 'WebstoreSetupContext';

const setupRoutePrefix = APP_ROUTES.vendor.webstore.newsetup + '/';
const getSetupStepRoute = (step) => `${setupRoutePrefix}${step}`;

const useWebstoreSetupWizard = () => {
    const { vendor } = useVendorContext();
    const [webstoreSetupProps] = React.useContext(WebstoreSetupContext);
    const { webstore, progress, totalSteps, currentStepCount, stepsArray, patchFunction } =
        webstoreSetupProps;

    const navigate = useNavigate();

    const getStep = (indexOffset) => {
        const currentIndex = stepsArray.indexOf(webstore.CurrentSetupStep);
        return stepsArray[currentIndex + indexOffset];
    };

    const onBack = () => {
        const previousStep = getStep(-1);
        navigate(getSetupStepRoute(previousStep));
    };

    const onSkip = () => {
        const nextStep = getStep(1);
        navigate(getSetupStepRoute(nextStep));
    };

    const returnToBusinessLocations = async () => {};

    const onReturnToBusinessLocations = vendor.webstoreCount > 1 ? returnToBusinessLocations : null;

    const onSubmit = (formData) => {
        if (formData) {
            webstore.updateWebstore(formData);
        }
        const nextStep = getStep(1);
        if (nextStep) {
            navigate(getSetupStepRoute(nextStep));
        }
        // try {
        //     if (formData) {
        //         webstore.updateWebstore(formData);
        // const patch = await patchFunction(webstore.forApi);
        // if (patch.ok) {
        //     webstore.setFromApi(patch.data);
        // } else {
        //     console.error(patch.originalError);
        // }
        //     }
        //     const nextStep = getStep(1);
        //     if (nextStep) {
        //         console.log(">> getSetupStepRoute: ", getSetupStepRoute(nextStep));
        //         navigate(getSetupStepRoute(nextStep));
        //     } else {
        //         webstore.CurrentSetupStep = COMPLETE_STEP;
        //         await webstoreSetupProps.updateStep();
        //         navigate(APP_ROUTES.vendor.webstore.root);
        //     }
        // } catch (err) {
        //     console.error(err);
        // }
    };

    return {
        onSubmit,
        onBack,
        onSkip,
        onReturnToBusinessLocations,
        progress,
        totalSteps,
        currentStepCount,
    };
};

const NewWebstoreSetupWizardProvider = ({ children }) => {
    const webstoreApi = WebstoreService();
    const { webstore } = useWebstoreContext();
    const location = useLocation();
    const navigate = useNavigate();

    const getMaxStepCount = () => {
        let stepCount = Object.keys(NewWebstoreSetupStepForms).length;
        return stepCount;
    };

    const recalcSteps = (options) => {
        const { locationChanged } = options;
        const updateStep = webstoreSetup.updateStep;

        if (locationChanged) {
            const currentPage = location.pathname.slice(
                location.pathname.indexOf(setupRoutePrefix) + setupRoutePrefix.length
            );

            const validPages = Object.values(NewWebstoreSetupStepForms);

            if (validPages.includes(currentPage)) {
                if (webstore.CurrentSetupStep !== currentPage) {
                    webstore.CurrentSetupStep = currentPage;
                    updateStep();
                }
            } else {
                if (!validPages.includes(webstore.CurrentSetupStep)) {
                    webstore.CurrentSetupStep = NEW_DEFAULT_STEP;
                    updateStep();
                }
                navigate(getSetupStepRoute(webstore.CurrentSetupStep));
            }
        }

        let stepsArray = webstoreSetup.stepsArray;

        const currentStepCount = stepsArray.indexOf(webstore.CurrentSetupStep) + 1;

        const totalSteps = stepsArray.length > 1 ? stepsArray.length : getMaxStepCount();

        const progress = currentStepCount / totalSteps;

        setWebstoreSetup({
            ...webstoreSetup,
            progress: progress,
            totalSteps: totalSteps,
            currentStepCount: currentStepCount,
            stepsArray: stepsArray,
        });
    };

    const updateStep = async () => {};

    const defaultWebstoreSetupProps = {
        patchFunction: webstoreApi.patchWebstore,
        stepsArray: [...Object.values(NewWebstoreSetupStepForms)],
        progress: 0,
        totalSteps: getMaxStepCount(),
        currentStepCount: 1,
        webstore,
        updateStep,
    };

    const [webstoreSetup, setWebstoreSetup] = useState(defaultWebstoreSetupProps);

    useEffect(() => {
        recalcSteps({ locationChanged: true });
    }, [location]);

    return (
        <WebstoreSetupContext.Provider value={[webstoreSetup, setWebstoreSetup]}>
            {children}
        </WebstoreSetupContext.Provider>
    );
};

export { useWebstoreSetupWizard, NewWebstoreSetupWizardProvider };
