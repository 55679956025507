import * as Yup from 'yup';
import i18n from 'i18n';

const passwordRequirementsMessage = i18n.t('Forms.Validation.PasswordRequirements', {
    format: i18n.t('Forms.Validation.PasswordInstruction'),
});

const vendorBasicDetailsFormSchemaWrapper = () => {
    const vendorBasicDetailsFormSchema = Yup.object()
        .shape({
            Firstname: Yup.string()
                .max(
                    120,
                    i18n.t('Forms.Validation.CannotExceedCharacterLimit', {
                        characterLimit: '120',
                    })
                )
                .matches(
                    /^[A-Za-z\s]*$/,
                    i18n.t('Name should not contain any special characters or numbers', {
                        field: i18n.t('Forms.Labels.Firstname'),
                    })
                )
                .required(i18n.t('Forms.Validation.NameRequired')),
            Lastname: Yup.string()
                .max(
                    120,
                    i18n.t('Forms.Validation.CannotExceedCharacterLimit', {
                        characterLimit: '120',
                    })
                )
                .matches(
                    /^[A-Za-z\s]*$/,
                    i18n.t('Name should not contain any special characters or numbers', {
                        field: i18n.t('Forms.Labels.Firstname'),
                    })
                )
                .required(i18n.t('Forms.Validation.NameRequired')),
            Emailaddress: Yup.string()
                .email(i18n.t('Forms.Validation.ValidEmail'))
                .required(i18n.t('Forms.Validation.EmailRequired')),
            Phonenumber: Yup.string()
                .max(
                    120,
                    i18n.t('Forms.Validation.CannotExceedCharacterLimit', {
                        characterLimit: '120',
                    })
                )
                .required(i18n.t('Forms.Validation.PhonenumRequired')),
            Enterpassword: Yup.string()
                .required(i18n.t('Forms.Validation.PasswordRequired'))
                .min(8, passwordRequirementsMessage)
                .matches(
                    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^a-zA-Z\d\s])[A-Za-z\d\S]{8,}$/,
                    passwordRequirementsMessage
                ),
            Confirmpassword: Yup.string()
                .required(i18n.t('Forms.Validation.ConfirmPasswordRequied'))
                .oneOf([Yup.ref('Enterpassword')], i18n.t('Forms.Validation.PasswordsMustMatch')),
            Agree: Yup.bool().oneOf([true], i18n.t('Forms.Labels.SignupAgreement')),
        })
        .required();

    return vendorBasicDetailsFormSchema;
};

export { vendorBasicDetailsFormSchemaWrapper };
