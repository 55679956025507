import { FormRow, socialLinksPrefixes } from 'framework';
import { TextInput } from 'dbi-pearl-ui-kit';
import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { SocialLinksSectionStyled } from './SocialLinksSectionNew.styled';
import { useTranslation } from 'react-i18next';
import TooltipComponent from 'vendor/components/Utility/TooltipComponent/TooltipComponent';
const socials = ['Website'];

const SocialLinksSection = () => {
    const { watch, getValues, setValue } = useFormContext();
    const { t } = useTranslation();
    const [currentTarget, setCurrentTarget] = useState(null);
    const [websiteFieldName] = [...socials];

    const cleanLink = (name, url) => {
        if (url) {
            const reduced = url.replace(/(^\w+:|^)\/\//, '');
            if (name) {
                if (name === websiteFieldName) {
                    return reduced;
                }
            }
            return url;
        }
    };

    useEffect(() => {
        const subscription = watch((value, { name }) => {
            setCurrentTarget(name);
        });
        return () => subscription.unsubscribe();
    }, [watch]);

    useEffect(() => {
        if (currentTarget) {
            if (socials.includes(currentTarget)) {
                setValue(currentTarget, cleanLink(currentTarget, getValues(currentTarget)));
            }
        }
    }, [watch(currentTarget)]);

    return (
        <SocialLinksSectionStyled data-testid="social-links-section">
            <div className="link-row">
                <FormRow
                    className="websiteField"
                    data-testid="website-link"
                    columns={[
                        <div>
                            <TextInput
                                formFieldName={websiteFieldName}
                                preText={socialLinksPrefixes.website.displayText}
                                providedLabel={t(`Forms.BusinessInformation.WebsiteTitle`)}
                            ></TextInput>
                        </div>,
                    ]}
                />
                <div className="tooltipWebsite">
                    <TooltipComponent
                        showCloseButton={false}
                        header={''}
                        width={'291'}
                        height={'95'}
                        bottomSmall={'148'}
                        leftSmall={'-610'}
                        arrowBottomSmallscreen={'-21'}
                        arrowLeftSmallscreen={'91'}
                        arrowBottom={'-22'}
                        arrowLeft={'90'}
                        bottom={'148'}
                        left={'-605'}
                        content={t(`Forms.BusinessInformation.SocialTooltip`)}
                    />
                </div>
            </div>
        </SocialLinksSectionStyled>
    );
};

export { SocialLinksSection };
