import { PearlBlack } from 'assets/logos/LogoImages';
import { UserChoiceLoginStyled } from './UserChoiceLogin.styled';
import { useTranslation } from 'react-i18next';
import { SolidButton } from 'dbi-pearl-ui-kit';
import { APP_ROUTES } from 'shared/constants/navigation';
import { useNavigate } from 'react-router-dom';
import { App } from '@capacitor/app';
import { Plugins } from '@capacitor/core';
import { useAuthUserContext } from '../../../../contexts/AuthUserContext';
import { IdentityProviders } from '../../../../lib/apis/TokenService';
import { setAppName, setAuthType, setNewVendor } from '../../../hooks/AppName';
import { PearlApps, PearlModalSizes } from '../../../../framework';
import { useState } from 'react';
import { useStore } from 'stores/Store';

const openUserChoiceLoginModal = (openModal, closeModal, screenIsLarge, authType) => {
    const modalSize = !!screenIsLarge
        ? PearlModalSizes.FORMS.VENDOR.WEB
        : PearlModalSizes.FORMS.VENDOR.MOBILE;

    openModal(<UserChoiceLogin authType={authType} closeModal={closeModal} />, modalSize);
};

const UserChoiceLogin = (props) => {
    const { authType, closeModal } = props;
    const { t } = useTranslation();
    const authUserContext = useAuthUserContext();
    const navigate = useNavigate();
    const { Browser } = Plugins;
    const { appData } = useStore();

    const [isLoggingIn, setIsLoggingIn] = useState(false);

    const doLogin = (appName) => {
        setIsLoggingIn(true);
        setAppName(appName);
        setAuthType(authType);

        // native app stuff
        App.addListener('appUrlOpen', (data) => {
            const params = data.url.split('://').pop();
            App.removeAllListeners();
            Browser.close();
            navigate({
                pathname: APP_ROUTES.authCallback,
                search: params,
            });
        });

        setTimeout(() => {
            if (
                authType === 'signup' &&
                appName === 'vendor' &&
                appData.isFeatureEnabled('newVendor')
            ) {
                setNewVendor('newVendor');
                navigate(APP_ROUTES.vendorNew);
            } else if (
                authType === 'login' &&
                appName === 'vendor' &&
                appData.isFeatureEnabled('newVendor')
            ) {
                setNewVendor('newVendor');
                navigate(APP_ROUTES.vendorLogin);
            } else {
                authUserContext.login(IdentityProviders.dbiSso, authType);
            }
        }, 1);

        closeModal();
    };

    return (
        <UserChoiceLoginStyled>
            <img
                className="headerLogo"
                src={PearlBlack.default}
                alt={t('Navigation.GlobalNavigationBar.PearlLogoAlt')}
            />
            <div className="text-section">
                <h1>{t('Forms.Subtitle.Header')}</h1>
                <p> {t('Forms.Subtitle.LoginModal')}</p>
            </div>
            <div className="button-section">
                <SolidButton
                    type="button"
                    disabled={isLoggingIn}
                    data-testid="login-as-bride-button"
                    onClick={() => doLogin(PearlApps.EVENT_USER)}
                >
                    {t(`Forms.Subtitle.Bride`)}
                </SolidButton>
                <SolidButton
                    type="button"
                    disabled={isLoggingIn}
                    data-testid="login-as-vendor-button"
                    onClick={() => doLogin(PearlApps.VENDOR)}
                >
                    {t(`Forms.Subtitle.Vendor`)}
                </SolidButton>
            </div>
        </UserChoiceLoginStyled>
    );
};

export { UserChoiceLogin, openUserChoiceLoginModal };
