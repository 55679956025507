import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { usePearlModal } from 'framework';
import {
    ModalContainer,
    ModalTitle,
    VerificationResendLink,
} from './VendorBasicDetailsForm.styled';
import { SolidButton, PearlIcon, IconSizes } from 'dbi-pearl-ui-kit';
import OTPInput from './OTPInput';
import { VendorAuthService } from 'lib/apis/VendorAuthService';
import TooltipComponent from '../Utility/TooltipComponent/TooltipComponent';

const OTP_LENGTH = 6;

const VerificationModalContent = ({ onVerify, email }) => {
    const [emailOtpValues, setEmailOtpValues] = useState('');
    const [emailOTPError, setEmailOTPErrror] = useState('');
    const vendorAuthService = VendorAuthService();
    const { closeModal } = usePearlModal();
    const { t } = useTranslation();
    const [isResendDisabled, setIsResendDisabled] = useState(true);
    const [timer, setTimer] = useState(60);
    const [isButtonDisabled, setIsButtonDisabled] = useState(true);

    useEffect(() => {
        if (isResendDisabled && timer > 0) {
            const countdown = setInterval(() => {
                setTimer((prev) => {
                    if (prev <= 1) {
                        clearInterval(countdown);
                        setIsResendDisabled(false);
                        return 0;
                    }
                    return prev - 1;
                });
            }, 1000);

            return () => clearInterval(countdown);
        }
    }, [isResendDisabled, timer]);

    useEffect(() => {
        const isEmailFilled = emailOtpValues.length === 6;
        setIsButtonDisabled(!isEmailFilled);
    }, [emailOtpValues]);

    const handleVerifyBtn = async () => {
        try {
            const results = await vendorAuthService.verifyEmailOTP({
                email,
                otp: emailOtpValues,
            });
            if (onVerify && results?.ok) {
                onVerify();
                closeModal();
            }
        } catch (e) {
            console.error('Email OTP verification failed');
            setEmailOTPErrror(e);
        }
    };

    const handleResendEmailOTP = async () => {
        if (!isResendDisabled) {
            setIsResendDisabled(true);
            setTimer(60);
            setEmailOTPErrror('');
            setEmailOtpValues('');

            try {
                await vendorAuthService.resendEmailOTP(email);
            } catch (error) {
                setEmailOTPErrror('Failed to resend Email OTP');
                console.error('>> Failed to resend Email OTP:', error);
            }
        }
    };

    return (
        <ModalContainer data-testid="modal-container">
            <ModalTitle>{t('Forms.BasicDetails.VerificationModalContent.Title')}</ModalTitle>
            <div className="otpSection">
                <div className="iconWithLabel">
                    <PearlIcon
                        data-testid="mocked-icon"
                        className="iconStyle"
                        iconName="email"
                        size={IconSizes.LG}
                    />
                    <div className="labelStyle">
                        {t('Forms.BasicDetails.VerificationModalContent.EmailCode')}
                    </div>
                </div>
                <div className="verificationCode">
                    <div className="textStyle">
                        {t('Forms.BasicDetails.VerificationModalContent.EnterEmailCode') + email}
                    </div>
                    <OTPInput
                        length={OTP_LENGTH}
                        values={emailOtpValues}
                        setValues={setEmailOtpValues}
                    />
                    {emailOTPError && <div className="emailError">{emailOTPError}</div>}
                    <div className={`resendButton-${isResendDisabled}`}>
                        <VerificationResendLink
                            className={`isResendDisabled-${isResendDisabled}`}
                            onClick={handleResendEmailOTP}
                        >
                            {t('Forms.BasicDetails.VerificationModalContent.ResendOTP')}
                        </VerificationResendLink>
                        <TooltipComponent
                            header={''}
                            showCloseButton={false}
                            width={'291'}
                            height={'95'}
                            bottom={'132'}
                            left={'570'}
                            arrowBottom={'-22'}
                            arrowLeft={'13'}
                            content={t(
                                'Forms.BasicDetails.VerificationModalContent.TooltipContent'
                            )}
                        />
                    </div>
                </div>
            </div>
            <div className="verifyBtnStyle">
                <SolidButton
                    disabled={isButtonDisabled}
                    onClick={handleVerifyBtn}
                    className="VerifyBtnStyle"
                >
                    {t('Forms.BasicDetails.VerificationModalContent.Verify')}
                </SolidButton>
            </div>
        </ModalContainer>
    );
};

export default VerificationModalContent;
