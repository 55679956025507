import { useAuthUserContext } from '../../../contexts/AuthUserContext';
import { IdentityProviders } from '../../../lib/apis/TokenService';
import { setAppName } from '../../../shared/hooks/AppName';
import { PearlApps } from '../../../framework';
import { useLocation, useNavigate } from 'react-router-dom';
import { StorageKeys, StorageService } from '../../../lib/StorageService';
import { APP_ROUTES } from 'shared/constants/navigation';
import { useStore } from 'stores/Store';
import { useEffect } from 'react';

const VendorSignupRedirect = () => {
    const location = useLocation();
    const storageService = StorageService();
    const navigate = useNavigate();
    const { appData } = useStore();
    if (location.state?.redirect) {
        storageService.set(StorageKeys.internalCallbackUrl, location.state.redirect);
    }
    const authUserContext = useAuthUserContext();
    setAppName(PearlApps.VENDOR);
    useEffect(() => {
        if (appData.isFeatureEnabled('newVendor')) {
            navigate(APP_ROUTES.vendorNew);
        } else {
            authUserContext.login(IdentityProviders.dbiSso);
        }
    }, []);

    return <div data-testid="redirect-vendor">Redirecting...</div>;
};

export default VendorSignupRedirect;
