import React from 'react';
import styled from 'styled-components';
import { SolidButton, IconName, GhostButton } from 'dbi-pearl-ui-kit';
import { useTranslation } from 'react-i18next';
import { BreakpointSizes } from 'dbi-pearl-ui-kit';
import { useFormContext } from 'react-hook-form';

const VendorAccountSetupStepFooterStyled = styled.footer`
    position: fixed;
    right: 0;
    bottom: 0;
    width: 65%;
    z-index: 1;
    background: ${(props) => props.theme.colors.background.base};

    .navigationButtonWrapper {
        display: flex;
        justify-content: end;
        padding-bottom: 12px;
        margin-right: 48px;
        button {
            width: 204px;
        }
        .backButton {
            margin-right: 24px;
        }
    }

    .navigationProgress {
        width: ${(props) => props.progress * 100}%;
        background: ${(props) =>
            `linear-gradient(90deg, ${props.theme.colors.primary.base} 0%, #ff5680 100%)`};
        height: 5px;
    }
    .navigationProgressBar {
        margin-bottom: 12px;
        background: ${(props) => props.theme.colors.background.base};
    }
    ${(props) => props.theme.pearlBreaks.up(BreakpointSizes.MD)} {
        .submitStyle {
            button {
                width: 204px;
            }
        }
        .continueStyle {
            button {
                width: 204px;
            }
        }
    }
    ${(props) => props.theme.pearlBreaks.down(BreakpointSizes.SM)}{
        .navigationButtonWrapper {
            margin: 16px;
        }    
    }   
        @media (min-width: 450px) and (max-width: 500px) {
        .navigationButtonWrapper {
            margin-left: 32px;
            margin-right: 32px;
        }
    }   
    }   
    ${(props) => props.theme.pearlBreaks.down(BreakpointSizes.MD)} {
        .navigationButtonWrapper {
            display: flex;
            justify-content: space-between;
            align-items: center;
            gap: 24;
            .backButton {
                width: 100%;
                padding-bottom: 12px;
                margin-right: 24px;
                button {
                    width: 100%;
                }
            }
            .nextButton {
                width: 100%;
                padding-bottom: 12px;
                button {
                    width: 100%;
                }
            }
            .completeButton {
                width: 100%;
                padding-bottom: 12px;
                button {
                    width: 100%;
                }
            }
            .skipButton {
                width: 100%;
                padding-bottom: 12px;
                button {
                    width: 100%;
                }
            }
            .submitStyle {
                width: 100%;
                padding-bottom: 12px;
                display: flex;
                justify-content: flex-end;
               @media(max-width: 500px) {
                button {
                    width: 100%;
                }
               }
            }
            .continueStyle {
                width: 100%;
                padding-bottom: 12px;
                display: flex;
                justify-content: flex-end;
                @media(max-width: 500px) {
                 button {
                    width: 100%;
                 }
                }    
            }
        }
    }
    `;

const VendorAccountSetupStepFooter = (props) => {
    const {
        progress,
        handleOnBack,
        handleOnSkip,
        handleSubmit,
        isSubmit,
        isLetsGo,
        showBack,
        handleContinue,
    } = props;
    const { getValues } = useFormContext();
    const { t } = useTranslation();
    const finalStep = progress === 1;
    return (
        <VendorAccountSetupStepFooterStyled {...props}>
            <div className="navigationProgressBar">
                <div className="navigationProgress"></div>
            </div>
            <div className="navigationButtonWrapper">
                {showBack && (
                    <div className="backButton">
                        <SolidButton
                            data-testid="back-button"
                            startIcon={IconName.ARROW_LEFT}
                            disabled={!handleOnBack}
                            onClick={() => handleOnBack()}
                            variant="secondary"
                            iconVariant="arrow"
                        >
                            {t('Forms.Labels.Back')}
                        </SolidButton>
                    </div>
                )}
                {!isSubmit && !isLetsGo && !handleOnSkip && !finalStep && (
                    <div className="nextButton">
                        <SolidButton
                            data-testid="next-button"
                            type="submit"
                            endIcon={IconName.ARROW_RIGHT}
                            iconVariant="arrow"
                            {...props}
                        >
                            {t('Forms.Labels.Next')}
                        </SolidButton>
                    </div>
                )}
                {isSubmit && (
                    <div className="submitStyle">
                        <SolidButton
                            type="submit"
                            endIcon={IconName.ARROW_RIGHT}
                            onClick={() => handleSubmit(getValues())}
                        >
                            {t('Forms.Labels.Submit')}
                        </SolidButton>
                    </div>
                )}
                {!isSubmit && isLetsGo && (
                    <div className="continueStyle">
                        <SolidButton
                            type="submit"
                            endIcon={IconName.ARROW_RIGHT}
                            onClick={() => handleContinue()}
                        >
                            {t('Forms.BasicDetails.WelcomeBan.LetsGo')}
                        </SolidButton>
                    </div>
                )}
                {!handleOnSkip && finalStep && (
                    <div className="completeButton">
                        <SolidButton data-testid="complete-button" type="submit" {...props}>
                            {t('Forms.Labels.Complete')}
                        </SolidButton>
                    </div>
                )}
                {handleOnSkip && (
                    <div className="skipButton">
                        <GhostButton
                            data-testid="skip-button"
                            type="button"
                            onClick={() => handleOnSkip()}
                            endIcon={IconName.ARROW_RIGHT}
                            variant="accent"
                            iconVariant="arrow"
                        >
                            {t('Forms.Labels.Skip')}
                        </GhostButton>
                    </div>
                )}
            </div>
        </VendorAccountSetupStepFooterStyled>
    );
};

export { VendorAccountSetupStepFooter };
